<template>
    <section>
        <div class="leading">
            <ul>
                <router-link :to="{ name: 'admin.dashboard' }">
                    <li>
                        <i class="far fa-home"></i>
                        <span>Dashboard</span>
                    </li>
                </router-link>
                <router-link :to="{ name: 'admin.seasons' }">
                    <li>
                        <i class="fal fa-table"></i>
                        <span>Seasons</span>
                    </li>
                </router-link>
                <router-link :to="{ name: 'admin.players' }">
                    <li>
                        <i class="far fa-user"></i>
                        <span>Players</span>
                    </li>
                </router-link>
                <router-link :to="{ name: 'admin.teams' }">
                    <li>
                        <i class="far fa-users"></i>
                        <span>Teams</span>
                    </li>
                </router-link>
                <router-link :to="{ name: 'admin.standings' }">
                    <li>
                        <i class="far fa-line-height"></i>
                        <span>Standings</span>
                    </li>
                </router-link>
                <router-link :to="{ name: 'admin.news' }">
                    <li>
                        <i class="far fa-newspaper"></i>
                        <span>News</span>
                    </li>
                </router-link>
                <router-link :to="{ name: 'admin.content' }">
                    <li>
                        <i class="far fa-shapes"></i>
                        <span>Content</span>
                    </li>
                </router-link>
            </ul>
        </div>
        <!-- <div class="trailing">
            Button
        </div> -->
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 4rem);
    padding: 2rem 0;
    .leading{
        ul{
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            a{
                color: $text-regular;
                text-decoration: none;
                li{
                    transition: background $transition;
                    border-radius: $border-radius;
                    padding: .5rem .5rem;
                    margin-bottom: 0.2rem;
                    i{
                        width: 1.75rem;
                        text-align: center;
                    }
                    span{
                        font-size: .95rem;
                    }
                }
                &:hover, &.router-link-exact-active{
                    li{
                        background: $sidebar-button-background;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(lg){
    section{
        height: unset;
        .leading{
            ul{
                overflow: scroll;
                display: flex;
                align-items: center;
                padding-bottom: 1rem;
                a{
                    li{
                        margin-right: 1rem;
                        display: flex;
                        align-items: center;
                        i{
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
