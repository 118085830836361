<template>
    <main>
        <a-section-header>
            <template v-slot:title>
                Site Content
            </template>
        </a-section-header>
        <div class="row">
            <div class="col-12 mb-4">
                <a-textarea label="Global Navigation" placeholder="" :rows="2" class="mb-2" v-model="content['global.navigation']"/>
            </div>
            <div class="col-12 mb-4">
                <a-input label="Gleam Link (Rewards Page)" placeholder="https://gleam.io/xxxxx/sps-code-drop" class="mb-2" v-model="content['rewards.gleam']"/>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-4">
                <a-input label="YouTube Video ID" placeholder="MBlAvslyIaw" class="mb-2" v-model="content['index.hero.video']"/>
            </div>
            <div class="col-12 mb-4">
                <a-input label="Index About Title" placeholder="ABOUT SPLITGATE PRO SERIES" class="mb-2" v-model="content['index.about.title']"/>
            </div>
            <div class="col-12 mb-4">
                <a-textarea label="Index About Content" placeholder="Welcome to the home of Splitgate esports. Whether you are a new fan, spectator, or a diehard FPS player, Splitgate has the type of gameplay that constantly delivers exciting and memorable moments of competition. We plan to fuel more of those moments for years to come." :rows="6" class="mb-2" v-model="content['index.about.body']"/>
            </div>
            <div class="col-12 mb-4">
                <a-textarea label="Partners" placeholder="" :rows="2" class="mb-2" v-model="content['global.partners']"/>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-6">
                <a-input label="About Stat 1 Value" placeholder="8" class="mb-2" v-model="content['index.about.stat1.value']"/>
            </div>
            <div class="col-md-6">
                <a-input label="About Stat 1 Text" placeholder="Pro Teams" class="mb-2" v-model="content['index.about.stat1.text']"/>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-6">
                <a-input label="About Stat 2 Value" placeholder="4" class="mb-2" v-model="content['index.about.stat2.value']"/>
            </div>
            <div class="col-md-6">
                <a-input label="About Stat 2 Text" placeholder="Seasons" class="mb-2" v-model="content['index.about.stat2.text']"/>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-6">
                <a-input label="About Left Box Value" placeholder="$100,000" class="mb-2" v-model="content['index.about.leftbox.value']"/>
            </div>
            <div class="col-md-6">
                <a-input label="About Left Box Text" placeholder="PER SEASON" class="mb-2" v-model="content['index.about.leftbox.text']"/>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-6">
                <a-input label="About Right Box (Orange) Value" placeholder="$500,000+" class="mb-2" v-model="content['index.about.rightbox.value']"/>
            </div>
            <div class="col-md-6">
                <a-input label="About Right Box (Orange) Text" placeholder="IN TOTAL CASH &amp; PRIZING" class="mb-2" v-model="content['index.about.rightbox.text']"/>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a-button class="small" @click="saveContent()">Save</a-button>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            content: {},
        };
    },
    methods: {
        getContent:function(){
            axios.get('/api/admin/content').then(c => {
                if(c.status == 200){
                    this.content = c.data;
                }
            }).catch(e => {
                console.error(e);
            });
        },
        saveContent:function(){
            axios.post('/api/admin/content', this.content).then(c => {
                if(c.status == 200){
                    console.log(JSON.stringify(c.data));
                    alert('Updated Successfully');
                }
            }).catch(e => {
                console.error(e);
            });
        },
    },
    mounted(){
        this.getContent();
    }
}
</script>

<style>

</style>
