<template>
	<section id="hero">
		<div class="container">
			<div class="row wrapper">
				<div :class="[playing ? 'hidden' : '', 'col-lg-6 leading']">
					<img src="https://cdn.splitgateproseries.com/pro-series/elements/hero_character.png" alt="Splitgate Character Folded Arms">
					<div class="video-wrapper" v-if="content && content['index.hero.video']">
                        <youtube-iframe :video-id="content['index.hero.video']" player-width="100%" player-height="100%" @state-change="state"></youtube-iframe>
					</div>
					<div class="socials">
						<ul>
                            <li>
                                <a target="_blank" href="https://twitter.com/SGEsports"><i class="fab fa-twitter"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="http://discord.gg/wg6uCdDPhx"><i class="fab fa-discord"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.twitch.tv/splitgate"><i class="fab fa-twitch"></i></a>
                            </li>
							<li>
                                <a target="_blank" href="https://www.youtube.com/channel/UCdUA8I1gJ606kBa9MfTZIyw"><i class="fab fa-youtube"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://reddit.com/r/splitgate"><i class="fab fa-reddit-alien"></i></a>
                            </li>
						</ul>
					</div>
				</div>
				<div class="col-lg-6 trailing">
					<div class="logo">
						<img src="https://cdn.splitgateproseries.com/pro-series/logos/splitgate_prime.svg" alt="Splitgate Pro Series">
                        <div class="presenting" v-if="presenting">
                            <img class="powered" src="https://cdn.splitgateproseries.com/pro-series/elements/powered.svg" alt="Powered By">
                            <img class="partner" :src="presenting.logo" :alt="presenting.name">
                        </div>
					</div>
					<div class="buttons">
						<v-button class="red" href="https://playbeyond.com/game/6160aca39fb825001ef1fb0d/" @click="fathom('XDRSGTZS')">Join now</v-button>
						<v-button class="blue secondary" to="news">Recent News</v-button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            playing: false,
        }
    },
    computed: {
        ...mapState([
            'partners',
            'content',
        ]),
        presenting(){
            return this.partners.filter(function(p){ return p.presenting == true; })[0];
        },
        origin(){
            return window.location.href;
        }
    },
    methods: {
        fathom(goal){
            window.fathom.trackGoal(goal, 0);
        },
        state:function(event){
            if(event.data == 1){
                this.playing = true;
            }else{
                this.playing = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
#hero{
	padding-top: 9rem;
	background: url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
	background-size: cover;
	background-position: center top;
	.wrapper{
		.leading{
			position: relative;
			img{
				position: absolute;
				z-index: 1;
				bottom: 0;
				left: -8rem;
				height: 70%;
				pointer-events: none;
				opacity: 1;
				transition: all 600ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
			}
			.video-wrapper{
				padding-top: 56.25%;
				position: relative;
				overflow: hidden;
				border-radius: 1rem;
				border: solid 2px $red;
				background: $primary-black;
				display: flex;
				justify-content: center;
				align-items: center;
               .vue-youtube-iframe{
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
					border-radius: .5rem;
                }
			}
			.socials{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				padding: 2rem 0;
				ul{
					padding: unset;
					margin: unset;
					list-style-type: none;
					display: flex;
					align-items: center;
					li{
						margin-right: 2rem;
						&:last-child{
							margin-right: unset;
						}
						a{
							i{
								color: #fff;
								font-size: 1.5rem;
								transition: color $transition;
								&:hover{
									color: $red;
								}
							}
						}
					}
				}
			}
			&:hover, &.hidden{
				img{
					opacity: 0;
				}
			}
		}
		.trailing{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.logo{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 3rem;
				.presenting{
					display: flex;
					flex-direction: column;
					align-items: center;
				}
				img{
					width: 50%;
					&.powered{
						width: 45%;
						margin-bottom: 1rem;
					}
					&.partner{
						height: 2rem;
						margin-bottom: unset;
					}
				}
			}
			.buttons{
				display: flex;
				gap: 1rem;
				a{
					&:last-child{
						margin-right: unset;
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(lg){
	#hero{
		.wrapper{
			flex-direction: column-reverse;
			.trailing{
				margin-bottom: 4rem;
			}
		}
	}
}
@include media-breakpoint-down(md){
	#hero{
		background-size: 200%;
		padding-top: 7rem;
	}
}
@include media-breakpoint-down(sm){
	#hero{
		.wrapper{
			.leading{
				img{
					left: -24vw;
				}
				.socials{
					ul{
						li{
							margin-right: 1rem;
						}
					}
				}
			}
		}
	}
}
</style>
