<template>
  <a-header></a-header>
    <main class="container">
        <div class="row">
            <div class="col-lg-2">
                <a-sidebar/>
            </div>
            <div class="col-lg-10">
                <router-view v-slot="{ Component }">
                    <component :is="Component"/>
                </router-view>
            </div>
        </div>
    </main>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

</style>
