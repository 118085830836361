<template>
    <main>
        <section id="login">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-8 wrapper">
                        <form action="" method="post" @submit="submit">
                            <div class="input-wrapper">
                                <input type="text" :placeholder="email" v-model="data.email">
                                <small>{{ errors.email ? errors.email[0] : '' }}</small>
                            </div>
                            <div class="input-wrapper">
                                <input type="password" placeholder="••••••••••••" v-model="data.password">
                                <small>{{ errors.password ? errors.password[0] : '' }}</small>
                            </div>
                            <div class="checkbox-wrapper">
                                <input type="checkbox" v-model="data.remember">
                                <label for="">Remember Me</label>
                            </div>
                            <button type="submit">Log In</button>
                        </form>
                    </div>
                </div>
            </div>
        <img src="https://cdn.splitgateproseries.com/pro-series/backgrounds/login.png" alt="Splitgate Gaming Background">
        </section>
    </main>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data(){
        return {
            data: {},
            errors: {}
        }
    },
    computed:{
        email(){
            return 'admin@'+window.location.host;
        }
    },
    methods: {
        ...mapActions([
            'login'
        ]),
        submit(form){
            this.login(this.data).then(errors => {
                this.errors = errors;
            });
            form.preventDefault();
        },
        // register(){
        //     console.info('reg');
        //     axios.get('/sanctum/csrf-cookie').then(response => {
        //         axios.post('/register',{
        //             name_first: '',
        //             name_last: '',
        //             email: '',
        //             password: ''
        //         }).then(response => {
        //             console.log(response);
        //         }).catch(error => {
        //             if (error.response.status === 422) {
        //                 this.errors = error.response.data.errors || {};
        //             }
        //         });
        //     });
        // }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

section#login{
    background: url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .wrapper{
        position: relative;
        z-index: 1;
        form{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .input-wrapper{
                position: relative;
                width: 100%;
                input{
                    width: 100%;
                    font-size: 1.25rem;
                    text-transform: uppercase;
                    font-style: italic;
                    padding: .75rem 1.5rem;
                    outline: none;
                    border: solid 2px #23272B;
                    border-radius: .5rem;
                    background: #131719;
                    color: #fff;
                    transition: all 600ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
                    margin-bottom: 2.5rem;
                    &::placeholder{
                        color: #383D41;
                    }
                    &:focus{
                        border: solid 2px #4cc5f3;
                    }
                }
                small{
                    position: absolute;
                    left: 0;
                    bottom: .5rem;
                    color: red;
                }
            }
            .checkbox-wrapper{
                font-style: italic;
                text-transform: uppercase;
                margin-bottom: 1.25rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                input{
                    margin-right: .5rem;
                }
            }
            button{
                width: 100%;
                background:#FF5B00;
                color: #fff;
                font-style: italic;
                font-size: 1.25rem;
                border-radius: .5rem;
                padding: .75rem 1.5rem;
                text-decoration: unset;
                text-transform: uppercase;
                text-align: center;
                position: relative;
                isolation: isolate;
                overflow: hidden;
                border: solid 2px #FF5B00;
                transition: all 600ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
            }
        }
    }
    img{
        position: absolute;
        z-index: 0;
        bottom: -4rem;
        right: -2rem;
    }
}
@include media-breakpoint-down(lg){
    section#login{
        img{
            right: -6rem;
            bottom: -6rem;
        }
    }
}
@include media-breakpoint-down(md){
    section#login{
        img{
            display: none;
        }
    }
}
</style>
