<template>
    <header>
        <div class="container">
            <div class="row">
                <div class="col-12 wrapper">
                    <div class="leading">
                        <router-link :to="{ name: 'admin.dashboard' }" class="logo">
                            <img src="https://cdn.splitgateproseries.com/pro-series/logos/sps_admin.svg" alt="Logo">
                        </router-link>
                    </div>
                    <div class="trailing">
                        <a-avatar class="avatar"/>
                        <div class="dropdown-wrapper">
                            <div class="dropdown">
                                <ul>
                                    <li>
                                        <router-link target="_blank" :to="{ name: 'index' }">View Site</router-link>
                                    </li>
                                    <li>
                                        <div class="logout" @click="logout">Logout</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    methods: {
        ...mapActions([
            'logout'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
    header{
        position: relative;
        z-index: 10;
        background: $header-background;
        border-bottom: $lighter-foreground;
        .wrapper{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $header-height;
            .leading{
                .logo{
                    margin: 3rem 0;
                }
            }
            .trailing{
                position: relative;
                display: flex;
                align-items: center;
                ul{
                    display: flex;
                    list-style: none;
                    margin-bottom: 0;
                    margin-right: 2rem;
                    li{
                        a{
                            transition: $transition;
                            height: $header-height;
                            color: $text-light;
                            font-size: .875rem;
                            text-decoration: none;
                            font-weight: 500;
                            &:hover{
                                color: $text-regular;
                            }
                        }
                    }
                }
                .dropdown-wrapper{
                    position: absolute;
                    right: 0;
                    top: 100%;
                    display: flex;
                    max-height: 0;
                    padding-top: 1rem;
                    overflow: hidden;
                    transition: max-height .6s ease-in-out;
                    .dropdown{
                        background: #fff;
                        border-radius: .25rem;
                        padding: 1rem 1.25rem;
                        overflow: hidden;
                        ul{
                            list-style-type: none;
                            margin: unset;
                            padding: unset;
                            display: flex;
                            flex-direction: column;
                            li{
                                margin: unset;
                                padding: unset;
                                margin-bottom: .5rem;
                                a{
                                    white-space: nowrap;
                                }
                                &:last-child{
                                    margin-bottom: unset;
                                }
                                .logout{
                                    cursor: pointer;
                                    transition: $transition;
                                    height: $header-height;
                                    color: $text-light;
                                    font-size: .875rem;
                                    text-decoration: none;
                                    font-weight: 500;
                                    height: unset;
                                    &:hover{
                                        color: $text-regular;
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover{
                    .dropdown-wrapper{
                        max-height: 10rem;
                    }
                }
            }
        }
    }
</style>
