<template>
	<main>
		<hero/>
		<partners/>
		<about/>
        <teams/>
		<section id="recent-news">
			<div class="container">
				<div class="heading">
					<h2>Recent News</h2>
                	<v-button class="orange" to="news">All News</v-button>
				</div>
				<div class="row">
					<article-card class="article" v-for="article in articles.slice(0, 3)" :key="article.slug" :article="article"/>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState([
            'articles'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
#recent-news{
	padding-top: 5rem;
	position: relative;
	overflow: hidden;
	&::before{
		content: '';
		position: absolute;
		z-index: -1;
		width: calc(100% + 2rem);
		height: 48%;
		top: 0;
		left: -1rem;
		background: #23272B;
		border: solid 2px #3D4246;
	}
	.heading{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 2.5rem;
	}
}
@include media-breakpoint-down(sm){
	#recent-news{
		&::before{
			height: 40%;
		}
		padding: 3rem 0;
	}
}
</style>
