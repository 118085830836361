<template>
	<div class="col-12 event-wrapper">
		<div class="event">
			<div class="event_leading">
				<img :src="event.logo || 'https://cdn.splitgateproseries.com/pro-series/logos/sps.svg'" :class="event.logo ? '' : 'adjusted-width'" alt="Splitgate Pro Series">
				<h2>{{ event.name }}</h2>
			</div>
			<div class="event_trailing">
				<h2>{{ date }}</h2>
				<div class="button-wrapper">
                	<v-button :href="event.button_link" class="orange secondary" :disabled="!event.button_link" @click="fathom('XDRSGTZS')" v-if="event.button_text">{{ event.button_text }}</v-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const { DateTime } = require('luxon');
import { mapGetters } from 'vuex';
export default {
    props: ['event'],
    data(){
        return {
            secondsLeft: 1,
        }
    },
    computed: {
        ...mapGetters([
            'currentEvent'
        ]),
        prizepool(){
            return this.event.prizepool ? '$'+this.event.prizepool.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',') : '';
        },
        date(){
            return this.event.finish_at ?
                DateTime.fromISO(this.event.start_at).setZone('UTC').toFormat('dd LLL') +' - '+ DateTime.fromISO(this.event.finish_at).setZone('UTC').toFormat('dd LLL') :
                DateTime.fromISO(this.event.start_at).toFormat('dd LLL | h:mma ZZZZ');
        }
    },
    methods: {
        fathom(goal){
            window.fathom.trackGoal(goal, 0);
        },
    },
    mounted(){
       var t = this;
       setInterval(function(){
           t.secondsLeft =  Math.floor(DateTime.fromISO(t.event.start_at).diff(DateTime.now(), ['seconds']).seconds);
       }, 1000)
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.event-wrapper{
	margin-bottom: 1rem;
	&:last-child{
		margin-bottom: unset;
	}
	.event{
		background: $light-grey;
		border: solid 1px #3D4246;
		border-radius: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		transition: all .3s ease-in-out;
		.event_leading,.event_trailing{
			display: flex;
			align-items: center;
			h2{
				font-size: 1.125rem;
			}
		}
		.event_leading{
			flex-grow: 1;
			img{
				height: 2.5rem;
				margin-right: 3rem;
				transition: filter .3s ease-in-out;
				&.adjusted-width{
					height: 3.1rem;
				}
			}
		}
		.event_trailing{
			h2{
				margin-right: 10rem;
			}
			.button-wrapper{
				width: 10rem;
				.button{
					width: 100%;
				}
			}
		}
		&.closed{
			background: rgba($color: $light-grey, $alpha: .25);
			backdrop-filter: blur(4px);
			.event_leading{
				img{
					filter: saturate(0);
				}
			}
		}
		&.active{
			border: solid 1px $orange;
		}
	}
}
@include media-breakpoint-down(xl){
	.event-wrapper{
		.event{
			.event-leading,.event_trailing{
				h2{
					font-size: .825rem;
				}
			}
			.event_leading{
				img{
					margin-right: 2rem;
				}
			}
			.event_trailing{
				h2{
					margin-right: 2rem;
				}
			}
		}
	}
}
@include media-breakpoint-down(lg){
	.event-wrapper{
		.event{
			flex-direction: column;
			.event_leading, .event_trailing{
				width: 100%;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 1rem;
			}
			.event_leading{
				img{
					margin: unset;
					height: 2.5rem;
				}
			}
			.event_trailing{
				h2{
					margin: unset;
				}
				button{
					width: 100%;
				}
			}
		}
	}
}
</style>
