<template>
    <main>
         <a-section-header>
            <template v-slot:title>
                Dashboard
            </template>
            <template v-slot:button>
                <!-- <a-button>Add Season</a-button> -->
            </template>
        </a-section-header>
        <div class="row">
            <div class="col-4">
                <a-widget name="Players" value="0"/>
            </div>
            <!-- <div class="col-4">
                <a-widget name="Teams" value="0"/>
            </div> -->
        </div>
    </main>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

</style>
