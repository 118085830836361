<template>
    <div class="avatar">
        <p>{{ initials }}</p>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState([
            'user'
        ]),
        initials(){
            let f = this.user.name_first ? this.user.name_first.substring(0, 1) : '';
            let l = this.user.name_last ? this.user.name_last.substring(0, 1) : '';
            return (f+l).toUpperCase();
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.avatar{
    background: $light-bg;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        margin-bottom: unset;
        font-weight: 600;
    }
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
</style>
