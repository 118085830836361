<template>
  <div class="col-xl-4 sponsor-wrapper">
        <div class="sponsor">
            <div class="leading">
                <div class="sponsor-title">
                    <img src="https://cdn.splitgateproseries.com/pro-series/partners/astro.svg" alt="Astro Gaming Logo">
                    <h2>Season</h2>
                </div>
                <h1>sponsor AWARD</h1>
            </div>
            <div class="trailing">
                <h2>$1,000 Value Astro Bundle</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.sponsor-wrapper{
    .sponsor{
        padding: 4rem;
        background: $light-grey;
        border-radius: 1rem;
        border: solid 2px #3D4246;
        text-align: center;
        .leading{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
            .sponsor-title{
                display: flex;
                img{
                    height: 2rem;
                    margin-right: 1rem;
                }
                h2{
                    font-weight: 200;
                }
            }
            h1{
                color: $orange;
            }
        }
    }
}
@include media-breakpoint-down(xl){
     .sponsor-wrapper{
        margin-top: 3rem;
        .sponsor-title{
            justify-content: center;
        }
    }
}
@include media-breakpoint-down(lg){
    .sponsor-wrapper{
        margin-top: 1rem;
        .sponsor{
            padding: 2rem;
        }
    }
}
</style>