<template>
    <label for="">{{ label }}</label>
    <div class="select-wrapper">
        <select :value="modelValue" @input="this.$emit('update:modelValue', $event.target.value)">
            <slot></slot>
        </select>
        <i class="fas fa-caret-down"></i>
    </div>
</template>

<script>
export default {
    props: ['modelValue','label','disabled','error','class'],
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.select-wrapper{
    position: relative;
    margin-right: 1rem;
    &:last-child{
        margin-right: unset;
    }
    select{
        width: 100%;
        padding: .75rem 3rem .75rem 1.5rem;
        border-radius: .25rem;
        border: unset;
        appearance: unset;
    }
    i{
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>
