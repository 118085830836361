<template>
    <section id="partners">
        <div class="container">
            <div class="row">
                <div class="col-12 wrapper">
                    <h2>In Partnership With</h2>
                    <div class="divider"></div>
                    <div class="partner-wrapper" v-if="content['global.partners']">
                        <div class="partner" v-for="partner in JSON.parse(content['global.partners'])" :key="partner.name">
                            <img :src="partner.logo" :alt="partner.name">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState([
            'partners',
            'content'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
#partners{
    padding: 2.5rem 0;
    background: linear-gradient(90deg, #23272A 0%, #363B3F 100%),;
    border-top: solid 1px #3D4246;
    border-bottom: solid 1px #3D4246;
    overflow: hidden;
    .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2{
            margin-right: 2rem;
        }
        .partner-wrapper{
            margin-left: 2rem;
            display: flex;
            .partner{
                display: flex;
                flex-grow: 1;
                justify-content: center;
                align-items: center;
                border-radius: 1rem;
                padding: .75rem 2rem;
                background: #363B3F;
                border: solid 1px #5B6166;
                margin-right: 2rem;
                &:last-child{
                    margin-right: unset;
                }
                img{
                    width: 9rem;
                }
            }
        }
        .divider{
			position: relative;
			display: flex;
			flex-grow: 1;
			height: 2px;
			background: $blue;
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: $blue;
			}
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: $blue;
			}
			&.blue{
				background: $blue;
				&::before{
					background: $blue;
				}
				&::after{
					background: $blue;
				}
			}
		}
    }
}
@include media-breakpoint-down(xl){
    #partners{
        .wrapper{
            flex-direction: column;
            justify-content: center;
            h2{
                margin-right: unset;
                margin-bottom: 1.5rem;
            }
            .divider{
                display: none;
            }
        }
    }
}
@include media-breakpoint-down(sm){
    #partners{
        .wrapper{
            .partner-wrapper{
                flex-direction: column;
                justify-content: center;
                margin-left: unset;
                .partner{
                    margin-right: unset;
                    margin-bottom: 1rem;
                    height: 3.5rem;
                    &:last-child{
                        margin-bottom: unset;
                    }
                }
            }
        }
    }
}
</style>
