<template>
    <main>
        <a-section-header>
            <template v-slot:title>
                Player
            </template>
        </a-section-header>
        <form action="" method="post" @submit="submit">
            <div class="row mb-3">
                <div class="col-md-6">
                    <a-input label="Name" v-model="player.name" v-on:input="updateSlug" :error="errors.name ? errors.name[0] : null"/>
                </div>
                <div class="col-md-6">
                    <a-input label="Slug" v-model="player.slug" :error="errors.slug ? errors.slug[0] : null"/>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 mb-2">
                    <a-select v-model="player.team_id" label="Team" :error="errors.user_id ? error.user_id[0] : null">
                        <option value="">Select Team</option>
                        <option :value="team.id" v-for="team in teams" :key="team.id">{{ team.name }}</option>
                    </a-select>
                </div>
            </div>

            <hr>

            <h4 class="mb-3">Socials</h4>
            <div class="row">
                <div class="col-md-6 mb-2">
                    <a-input label="Twitter" v-model="player.twitter" placeholder="https://twitter.com/mrbeast"/>
                </div>
                <div class="col-md-6 mb-2">
                    <a-input label="Twitch" v-model="player.twitch" placeholder="https://www.twitch.tv/mrbeast6000"/>
                </div>
                <div class="col-md-6 mb-2">
                    <a-input label="YouTube" v-model="player.youtube" placeholder="https://www.youtube.com/c/MrBeast6000"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <a-button class="primary mt-3" type="submit">{{ $route.params.slug ? 'Update' : 'Save' }}</a-button>
                </div>
            </div>
        </form>
    </main>
</template>

<script>
export default {
    data() {
        return {
            player: {},
            errors: {},
            teams: {}
        };
    },
    methods: {
        updateSlug:function(){
            this.player.slug = this.player.name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
        },
        getPlayer:function(){
            axios.get('/api/admin/player',{
                params: {
                    slug: this.$route.params.slug
                }
            }).then(p => {
                if(p.status == 200){
                    this.player = p.data;
                    if(p.data && p.data.socials.filter(function(s){ return s.platform_id == 1 })[0]){
                        this.player.youtube = p.data.socials.filter(function(s){ return s.platform_id == 1 })[0].link;
                    }
                    if(p.data && p.data.socials.filter(function(s){ return s.platform_id == 2 })[0]){
                        this.player.twitter = p.data.socials.filter(function(s){ return s.platform_id == 2 })[0].link;
                    }
                    if(p.data && p.data.socials.filter(function(s){ return s.platform_id == 3 })[0]){
                        this.player.twitch = p.data.socials.filter(function(s){ return s.platform_id == 3 })[0].link;
                    }
                }
            }).catch(e => {
                console.error(e);
            });
        },
        getTeams:function(){
            axios.get('/api/admin/teams').then(teams => {
                if(teams.status == 200){
                    this.teams = teams.data;
                }
            })
        },
        submit(form){
            form.preventDefault();

            axios.post('/api/admin/player', this.player).then(response => {
                // console.log(response.data);
                console.log(JSON.stringify(response.data));
                alert('Updated Successfully');
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    // console.log(this.errors);
                }
            });
        },
    },
    mounted(){
        this.getTeams();
        if(this.$route.params.slug){
            this.getPlayer();
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

</style>
