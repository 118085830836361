<template>
    <main>
        <section id="article">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-7 article-wrapper" v-if="loadingArticle">
                        <div class="loading heading"></div>
                        <div class="loading heading"></div>
                        <div class="loading heading"></div>
                        <div class="loading-details">
                            <div class="loading detail"></div>
                            <div class="loading detail"></div>
                        </div>
                        <div class="image-wrapper loading">
                        </div>
                    </div>
                    <div class="col-lg-7 article-wrapper" v-else>
                        <h2>{{ article.title }}</h2>
                        <div class="details">
                            <h4>{{ date }}</h4>
                            <h4>{{ article.author.name_first+' '+article.author.name_last }}</h4>
                        </div>
                        <div class="image-wrapper">
                            <img :src="article.image" :alt="article.title" class="image">
                        </div>
                        <div class="article-body" v-html="article.body"></div>
                    </div>
                    <div class="col-lg-4 recent-news">
                        <h3 class="title">Recent News</h3>
                        <article-card class="article-card" v-for="article in articles.slice(0, 3)" :key="article.slug" :article="article" @click="getArticle"/>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import marked from 'marked';
export default {
    data() {
        return {
            loadingArticle: false,
            article: {
                author: {}
            }
        };
    },
    computed: {
        ...mapState([
            'articles'
        ]),
        date(){
            return DateTime.fromISO(this.article.published_at).toUTC().toFormat('dd LLL yyyy');
        },
        body(){
            return this.article.body ? marked(this.article.body) : '';
        }
    },
    methods: {
        getArticle:function(){
            if(!this.loadingArticle){
                this.loadingArticle = true;
                axios.get('/api/article',{
                    params: {
                        slug: this.$route.params.slug,
                    }
                }).then(a => {
                    if(a.status == 200){
                        this.article = a.data;
                        this.loadingArticle = false;
                    }
                }).catch(e => {
                    this.$router.push({ name: 'news' });
                });
            }
        }
    },
    watch: {
        $route(to) {
            // console.log(to);
            // this.getArticle();
        }
    },
    mounted(){
        this.getArticle();
    }
}
</script>



<style lang="scss">
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
#article{
    background: radial-gradient(100% 100% at 50% 0%, rgba(19, 23, 25, 0) 0%, #131719 100%), url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
	background-size: 100%;
	background-position: center top;
    padding-top: 9rem;
    .article-wrapper{
        h2{
            margin-bottom: 1rem;
        }
        .details{
            display: flex;
            align-items: center;
            margin-bottom: 3rem;
            h4{
                margin-right: 2rem;
                color: #AEB3B9;
                &:last-child{
                    margin-right: unset;
                }
            }
        }
        .image-wrapper{
            position: relative;
            border-radius: 1rem;
            border: solid 2px $orange;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 16 / 9;
            margin-bottom: 1.5rem;
            .image{
                background: url('https://source.unsplash.com/random/1920x1080?gaming') no-repeat;
                background-size: cover;
                background-position: center center;
                overflow: hidden;
                position: relative;
                width: 100%;
                height: 100%;
                cursor: pointer;
                margin: .25rem;
                border-radius: .8rem;
                transition: transform $transition;
            }
            &.loading{
                border: unset;
                border-radius: 1rem;
            }
        }
        .article-body{
            img{
                width: 100%;
            }
            a{
                transition: color 0.2s;
                color: $blue;
                text-decoration: none;
                &:hover{
                    color: #fff;
                }
            }
        }
        .loading{
            background: $light-grey;
            margin-bottom: .5rem;
            width: 100%;
            border-radius: .5rem;
            position: relative;
            overflow: hidden;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                background: linear-gradient(90deg, #23272A 0%, #2A3136 52.6%, #23272A 100%);
                background-repeat: no-repeat;
                position: relative;
                width: 100%;
                height: 100%;
                display: block;
                animation: 2s placeHolderShimmer linear infinite;
            }
            @keyframes placeHolderShimmer {
                0% {
                    transform: translateX(-1000px);
                }

                100% {
                    transform: translateX(1000px);
                }
            }
            @keyframes placeHolderShimmerDetails{
                0% {
                    transform: translateX(-200px);
                }

                100% {
                    transform: translateX(200px);
                }
            }
            &.heading{
                height: 2rem;
                margin-bottom: 1rem;
                &:nth-child(2){
                    width: 85%;
                }
                 &:nth-child(3){
                    width: 60%;
                }
            }
            &.detail{
                overflow: hidden;
                height: 1rem;
                width: 20%;
                margin-right: 2rem;
                &:last-child{
                    margin-right: unset;
                }
                &::before{
                    width: 200%;
                    animation: 1.5s placeHolderShimmerDetails linear infinite;
                }
            }
        }
        .loading-details{
            margin-top: 2rem;
            display: flex;
            align-items: center;
            margin-bottom: 3rem;
        }
    }
    .recent-news{
        h3.title{
            margin-bottom: 2rem;
        }
        .article-card{
            width: 100%;
        }
    }
    iframe{
        width: 100%;
        height: 26rem;
    }
}
@include media-breakpoint-down(xxl){
    #article{
        iframe{
            height: 18rem;
        }
    }
}
@include media-breakpoint-down(lg){
    #article{
        .article-wrapper{
            margin-bottom: 3rem;
        }
        iframe{
            height: 24.5rem;
        }
    }
}
@include media-breakpoint-down(md){
    #article{
        iframe{
            height: 18rem;
        }
    }
}
@include media-breakpoint-down(sm){
    #article{
        iframe{
            height: 52vw;
        }
    }
}
</style>
