<template>
  <main>
       <div class="container" v-if="processing || gleam == null">
        <div class="row">
            <div class="col-12 inactive">
                <div class="loading" v-if="processing"><i class="fas fa-circle-notch fa-spin"></i> Loading...</div>
                <h3 v-else>We're not running any giveaways right now, please check back later.</h3>
            </div>
        </div>
      </div>
    <a class="e-widget no-button" :href="gleam" rel="nofollow" v-else>SPS Code Drop</a>
  </main>
</template>

<script>
export default {
    data() {
        return {
            processing: true,
            gleam: null,
        };
    },
    methods: {
        getRewards(){
            this.processing = true;
            axios.get('/api/content').then(content => {
                if(content.status == 200){
                    this.processing = false;
                    if(content.data['rewards.gleam']){
                        this.gleam = content.data['rewards.gleam'];
                        let gleam = document.createElement('script')
                        gleam.setAttribute('src', 'https://widget.gleamjs.io/e.js')
                        document.head.appendChild(gleam);
                    }
                }
            });
        },
    },
    mounted() {
        this.getRewards()
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
main{
    background: radial-gradient(100% 100% at 50% 0%, rgba(19, 23, 25, 0) 0%, #131719 100%), url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
	background-size: 100%;
	background-position: center top;
    padding-top: 9rem;
    .inactive{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(100vh - 28rem);
    }
}
</style>
