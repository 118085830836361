<template>
    <div class="team-wrapper col-12">
        <div class="team">
            <div class="team-position">
                <h2>01</h2>
            </div>
            <div class="team-details">
				<img src="https://cdn.splitgateproseries.com/pro-series/logos/sps.svg" alt="Splitgate Pro Series">
                <h2>Pro Series Team</h2>
            </div>
            <div class="team-scoring">
                <div class="team-scoring_wl">
                    <h2>100%</h2>
                </div>
                <div class="team-scoring_points">
                    <h2>3-0</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.team-wrapper{
    margin-bottom: 1rem;
    &:last-child{
        margin-bottom: unset;
    }
    .team{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $light-grey;
        border: solid 1px #3A3E42;
        border-radius: .5rem;
        padding-right: 4rem;
        overflow: hidden;
        .team-position{
            height: 4.5rem;
            width: 4.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #3A3E42;
            border-radius: .5rem;
        }
        .team-details{
            display: flex;
            flex-grow: 1;
            align-items: center;
            padding: .75rem 2rem;
            img{
                height: 3rem;
                margin-right: 2rem;
            }
        }
        .team-scoring{
            display: flex;
            align-items: center;
            .team-scoring_wl{
                margin-right: 5rem;
            }
        }
    }
    &:first-child{
        .team{
            border: solid 1px $orange;
            .team-position{
                background: linear-gradient(90deg, #FF5B00 0%, #FF8C4D 100%);
            }
        }
    }
}
@include media-breakpoint-down(lg){
    .team-wrapper{
        .team{
            padding-right: 2rem;
            .team-position,.team-details,.team-scoring{
                h2{
                    font-size: 1.5rem;
                }
            }
            .team-position{
                width: 3.5rem;
                height: 3.5rem;
            }
            .team-details{
                padding: 0 2rem;
                img{
                    height: 2rem;
                }
            }
            .team-scoring{
                .team-scoring_wl{
                    margin-right: 3rem;
                }
            }
        }
    }
}
@include media-breakpoint-down(md){
    .team-wrapper{
        margin-bottom: .5rem;
        .team{
            padding-right: 1rem;
             .team-position,.team-details,.team-scoring{
                h2{
                    font-size: 1rem;
                }
            }
            .team-position{
                height: 3rem;
                width: 3rem;
            }
            .team-details{
                padding: 0 1rem 0;
                img{
                    margin-right: 1rem;
                    height: 1.5rem;
                }
            }
            .team-scoring{
                .team-scoring_wl{
                    margin-right: 1.5rem;
                }
            }
        }
    }
}
@include media-breakpoint-down(sm){
    .team-wrapper{
        .team{
            .team-position{
                width: 1.5rem;
            }
            .team-details{
                padding: 0 .75rem;
                img{
                    margin-right: .75rem;
                }
            }
        }
    }
}
</style>
