<template>
    <div class="wrapper">
        <label for="textfield" v-html="label"></label>
        <div :class="[icon ? 'icon' : '', 'field-wrapper']">
            <i :class="icon" v-if="icon"></i>
            <textarea :placeholder="placeholder" :value="modelValue" @input="this.$emit('update:modelValue', $event.target.value)" :rows="rows"></textarea>
            <small>{{ error }}</small>
        </div>
    </div>
</template>

<script>
export default {
    props: ['modelValue','placeholder','label','icon','error','class','rows']
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.wrapper{
    display: flex;
    flex-direction: column;
    -webkit-appearance: none;
    appearance: none;
    label{
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: .25rem;
    }
    .field-wrapper{
        position: relative;
        small{
            color: red;
        }
        i{
            position: absolute;
            top: 1rem;
            left: .75rem;
            color: $text-light;
        }
        textarea{
            -webkit-appearance: none;
            appearance: none;
            background: $bg1;
            border: unset;
            border-radius: $border-radius;
            padding: .75rem;
            outline: unset;
            width: 100%;
            &::placeholder{
                color: $text-light;
            }
            &:disabled{
                background: rgba($foreground, 0.05);
            }
        }
        &.icon{
            textarea{
                padding: .75rem .75rem .75rem 2.5rem;
            }
        }
    }
}
@include media-breakpoint-down(md){
    .wrapper{
        margin-bottom: 1rem;
    }
}
</style>
