<template>
    <div :class="[item.type == 'team' ? 'team' : '','item-wrapper']" @click="openTeam">
        <div class="item">
            <div class="leading">
                <div class="position">
                    <h3>{{ item.position }}</h3>
                </div>
                <img :src="item.logo" :alt="item.name" v-if="item.logo">
                <h3>{{ item.name }}</h3>
            </div>
            <div class="trailing" v-if="item.type == 'player'">
                <h3>{{ item.points }}</h3>
            </div>
            <div class="trailing team" v-if="item.type == 'team'">
                <h3>{{ item.percentage }}%</h3>
                <h3>{{ item.wins }}-{{ item.losses }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['item'],
    methods: {
        ...mapActions(['openTeamModal']),
        openTeam(){
            if(this.item.type == 'team'){
                this.openTeamModal(this.item.id);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.item-wrapper{
    margin-bottom: 1rem;
    .item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $light-grey;
        border-radius: .5rem;
        border: solid 1px #3D4246;
        .leading{
            display: flex;
            align-items: center;
            .position{
                height: 3.5rem;
                width: 3.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #3A3E42;
                border-radius: .25rem;
                margin-right: 1rem;
            }
            img{
                height: 2rem;
                width: 2.5rem;
                object-fit: contain;
                margin-right: .75rem;
            }
        }
        .trailing{
            padding-right: 1rem;
            display: flex;
            align-items: center;
            &.team{
                h3{
                    &:first-child{
                        width: 3rem;
                        margin-right: 3rem;
                    }
                }
            }
        }
    }
    &.primary{
        .item{
            filter: drop-shadow(0px 0px 20px rgba(255, 91, 0, 0.2));
            border: solid 1px $orange;
            .leading{
                .position{
                    background: linear-gradient(90deg, #FF5B00 0%, #FF8C4D 100%);;
                }
            }
        }
    }
    &.secondary{
		.item{
            background: $light-grey;
            border: solid 1px #3A3E42;
            color: #fff;
            .leading{
                .position{
                    background: #3A3E42;
                    color: #fff;
                }
            }
        }
    }
    &:last-child{
        margin-bottom: 1rem;
    }
    &.team{
        cursor: pointer;
    }
}
@include media-breakpoint-down(md){
    .item-wrapper{
        .item{
            .leading{
                img{
                    margin-right: .5rem;
                    height: 1.75rem;
                    width: 1.75rem;
                }
                h3{
                    font-size: 1rem;
                }
                .position{
                    margin-right: .75rem;
                    width: 2rem;
                    h3{
                        font-size: 1rem;
                    }
                }
            }
            .trailing{
                h3{
                    font-size: 1rem;
                }
                &.team{
                    h3{
                        margin-right: .75rem;
                        &:first-child{
                            width: 2rem;
                            margin: .75rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
