<template>
	<div class="schedule">
		<div class="container">
			<div class="wrapper">
				<h1>{{ season.name }} Schedule</h1>
				<div class="divider"></div>
				<div class="select">
					<v-button class="orange" to="standings" v-if="current">Standings</v-button>
					<v-select v-model="selected" v-else>
						<option :value="season.slug" v-for="season in seasons" :key="season.slug">{{ season.name }}</option>
					</v-select>
				</div>
			</div>
			<div class="row">
				<event v-for="event in season.events" :key="event.slug" :event="event"/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
    props: ['current'],
    data(){
        return {
            selected: 'fall-2022'
        }
    },
    computed: {
        ...mapState([
            'seasons',
        ]),
        ...mapGetters([
            'currentSeason'
        ]),
        season(){
            let t = this;
            return this.current ? this.currentSeason : this.seasons.length && this.seasons.filter(function(s){ return s.slug == t.selected; }) ? this.seasons.filter(function(s){ return s.slug == t.selected; })[0] : [];
        }
    },
    watch: {
        currentSeason: function(current) {
            this.selected = current.slug;
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.schedule{
	padding: 4rem 0;
	.wrapper{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 4rem;
		h1{
			margin-right: 3rem;
		}
		a{
			margin-left: 2rem;
		}
		.divider{
			position: relative;
			display: flex;
			flex-grow: 1;
			height: 2px;
			background: $orange;
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: $orange;
			}
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: $orange;
			}
			&.blue{
				background: $blue;
				&::before{
					background: $blue;
				}
				&::after{
					background: $blue;
				}
			}
		}
		.select{
			margin-left: 2rem;
		}
	}
}
@include media-breakpoint-down(lg){
	.schedule{
		.wrapper{
			.divider{
				display: none;
			}
		}
	}
}
@include media-breakpoint-down(md){
	.schedule{
		padding: 3rem 0 1.5rem;
		.wrapper{
			flex-direction: column;
			justify-content: center;
			h1{
				margin-bottom: 1.5rem;
				margin-right: unset;
				text-align: center;
			}
			a{
				margin-left: unset;
			}
			.select{
				margin-left: unset;
			}
		}
	}
}
</style>
