<template>
    <div class="col-xl-3 col-sm-6 team-wrapper">
        <div class="team" @click="openTeamModal(team.id)">
            <img :src="team.logo" :alt="team.name" v-if="team.logo">
            <div class="team-name">
                {{ team.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: ['team'],
    methods: {
        ...mapActions(['openTeamModal'])
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.team-wrapper{
    &:nth-child(n+5){
		margin-top: 1.5rem;
	}
    .team{
        padding-top: 100%;
        background: $light-grey;
        position: relative;
        border-radius: 1rem;
        overflow: hidden;
        border: solid 1px #3D4246;
        cursor: pointer;
        transition: background .3s ease-in-out;
        img{
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%,-50%);
            max-height: 8rem;
            max-width: 12rem;
        }
        .team-name{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .75rem 0;
            background: #32373B;
            font-size: 1rem;
            font-weight: 800;
            text-transform: uppercase;
        }
    }
    &:hover{
        .team{
            background: #32373B;
        }
    }
}
@include media-breakpoint-down(xl){
    .team-wrapper{
        margin-bottom: 1.5rem;
        &:nth-child(n+5){
			margin-top: unset;
        }
    }
}
@include media-breakpoint-down(md){
    .team-wrapper{
        .team{
            img{
                max-height: 6rem;
                max-width: 8rem;
            }
        }
    }
}
@include media-breakpoint-down(sm){
    .team-wrapper{
        .team{
            img{
                max-height: 40%;
                max-width: 60%;
            }
        }
    }
}
</style>
