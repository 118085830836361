<template>
	<main>
		<section id="schedule">
        	<schedule/>
		</section>
	</main>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
section#schedule{
	background: radial-gradient(100% 100% at 50% 0%, rgba(19, 23, 25, 0) 0%, #131719 100%), url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
	background-size: 100%;
	background-position: center top;
	padding-top: 5rem;
}
@include media-breakpoint-down(md){
	section#schedule{
		background-size: 200%;
		padding-top: 4rem;
		.schedule{
			padding-bottom: unset;
		}
	}
}
</style>
