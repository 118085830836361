<template>
    <main>
        <section id="about-hero">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="heading">
                            <h1>Splitgate Pro Series Overview</h1>
                            <div class="divider"></div>
                            <img src="https://cdn.splitgateproseries.com/pro-series/logos/splitgate_prime.svg" alt="Splitgate Pro Series">
                        </div>
                    </div>
                </div>
                <div class="row wrapper">
                    <div class="col-lg-6 leading">
	                    <div class="video-wrapper" v-if="content && content['index.hero.video']">
                            <youtube-iframe :video-id="content['index.hero.video']" player-width="100%" player-height="100%"></youtube-iframe>
                        </div>
                    </div>
                    <div class="col-lg-5 trailing">
                        <p>1047 Games &amp; Logitech Esports Services have partnered together to launch the Splitgate Pro Series (SPS) and establish the future of Splitgate esports.</p>
                        <p>The Pro Series is the official program for Splitgate’s most passionate players and fans, designed to find and showcase the highest caliber of skill within the competitive Splitgate community. As the program evolves, the SPS will continue to support the top Splitgate talent while also providing a viable avenue for amateur players to find their path to pro and earn the title of Splitgate professional player.</p>
                        <p>The SPS will be hosted on the <a href="https://playbeyond.com">playbeyond.com</a> tournament platform for all Pro Series bracket tournaments, Challenger ladders, and Community Events.</p>
                        <div class="trailing-wrapper" style="justify-content: flex-start; gap: 1rem">
                            <v-button to="schedule">View Schedule</v-button>
                            <v-button href="https://cdn.splitgateproseries.com/pro-series/doc/SPS_Handbook_2022.pdf">Player Handbook</v-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="launch-season">
            <div class="container">
                <div class="heading">
                    <h2>Fall Season</h2>
                    <h2>$100,000 Prize Pool</h2>
                </div>
                <div class="col-12 details">
                    <div class="row">
                        <div class="col-xl-3">
                            <p>The Splitgate Pro Series features multiple levels of competition, including Pro and Amateur events.</p>
                        </div>
                        <div class="col-xl-3">
                            <p>Each Regular Season of the Pro Series features seven match days of round robin play, determining the seeds for the Season Finals.</p>
                        </div>
                        <div class="col-xl-3">
                            <p>Each season will end with a series of high-stakes Double Elimination tournaments. All Pro Series matches are broadcasted live on <a href="https://twitch.tv/splitgate">twitch.tv/splitgate</a></p>
                        </div>
                        <div class="col-xl-3">
                            <p>Amateur players can qualify for upcoming seasons of the Pro Series by competing in the Challengers Series on <a href="https://playbeyond.com">playbeyond.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="breakdown">
            <div class="container">
                <div class="row">
                    <div class="col-12 title">
                        <h1>OVER <span>$100,000</span> IN CASH AND PRIZES PER SEASON</h1>
                    </div>
                    <!-- <breakdown title="CHALLENGERS" prize="$5,000">
                        <slot>
                            <div class="placement">
                                <h2>1st</h2>
                                <h2>2nd</h2>
                                <h2>3rd</h2>
                                <h2>4th</h2>
                                <h2>5/6th</h2>
                            </div>
                            <div class="prize">
                                <h2>$2,000</h2>
                                <h2>$1,200</h2>
                                <h2>$800</h2>
                                <h2>$500</h2>
                                <h2>$250</h2>
                            </div>
                        </slot>
                    </breakdown>
                    <breakdown title="PRO MATCH BONUSES" prize="$35,000">
                        <slot>
                            <div class="placement">
                                <h2>Win</h2>
                                <h2>Loss</h2>
                            </div>
                            <div class="prize">
                                <h2>$1,000</h2>
                                <h2>$250</h2>
                            </div>
                        </slot>
                    </breakdown>
                    <breakdown title="SEASON FINALS" prize="$60,000">
                        <slot>
                            <div class="placement">
                                <h2>1st</h2>
                                <h2>2nd</h2>
                                <h2>3rd</h2>
                                <h2>4th</h2>
                                <h2>5/6th</h2>
                            </div>
                            <div class="prize">
                                <h2>$30,000</h2>
                                <h2>$15,000</h2>
                                <h2>$6,000</h2>
                                <h2>$4,000</h2>
                                <h2>$2,500</h2>
                            </div>
                        </slot>
                    </breakdown> -->
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { mapState } from 'vuex';
import marked from 'marked';

export default {
    data(){
		return{
            breakdowns: [
				{
					title: 'Season Finals',
					prize: '$60,000',
					positions: [
						{
							position: '1st',
							positionprize: '$60,000'
						}
					]
				}
			]
		}
	},
    computed: {
        ...mapState([
            'content'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
#about-hero{
    padding-top: 9rem;
	background: radial-gradient(74.3% 77.06% at 33.92% 100%, rgba(19, 23, 25, 0) 0%, #131719 100%), url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
    .heading{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 3rem;
        h1{
            max-width: 40%;
        }
        .divider{
            top: 2rem;
			position: relative;
			display: flex;
			flex-grow: 1;
			height: 2px;
			background: $blue;
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: $blue;
			}
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: $blue;
			}
			&.blue{
				background: $blue;
				&::before{
					background: $blue;
				}
				&::after{
					background: $blue;
				}
			}
		}
        img{
            margin-left: 2rem;
            height: 8rem;
        }
    }
    .wrapper{
        justify-content: space-between;
        align-items: flex-start;
        .leading{
            position: relative;
            display: flex;
            justify-content: center;
            .video-wrapper{
				padding-top: 56.25%;
				position: relative;
				overflow: hidden;
				border-radius: 1rem;
				border: solid 2px $red;
				background: $primary-black;
                width: 100%;
               .vue-youtube-iframe{
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
					border-radius: .5rem;
                }
			}
        }
        .trailing{
            padding-bottom: 2rem;
            a{
                color: #fff;
                text-decoration: none;
            }
            p{
                margin-bottom: 1rem;
            }
            .trailing-wrapper{
                margin-top: 2rem;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                p{
                    width: 15rem;
                    font-size: .8rem;
                    line-height: 1.3rem;
                    margin-bottom: unset;
                }
            }
        }
    }
}
#launch-season{
    overflow: hidden;
    padding: 5rem 0;
    background: #23272A;
    .heading{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;
        h2{
            &:last-child{
                margin-left: 2rem;
                border-radius: 1rem;
                padding: 1.5rem;
                background: $orange;
            }
        }
    }
    .details{
        position: relative;
        justify-content: space-between;
        a{
            color: #fff;
        }
    }
}
#breakdown{
    margin-top: 5rem;
    .title{
        margin-bottom: 5rem;
        h1{
            text-align: center;
            font-size: 5rem;
            line-height: 1.2;
            span{
                color: $orange;
            }
        }
    }
}
@include media-breakpoint-down(xl){
    #about-hero{
        .heading{
            h1{
                max-width: 60%;
            }
        }
        .wrapper{
            .trailing{
                .trailing-wrapper{
                    flex-direction: column;
                    p{
                        margin-bottom: 1rem;
                        width: unset;
                    }
                }
            }
        }
    }
    #launch-season{
        p{
            margin-bottom: 2rem;
        }
    }
    #breakdown{
        .title{
            h1{
                font-size: 2.5rem;
            }
        }
        .wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            .leading, .trailing{
                width: 70%;
            }
        }
    }
}
@include media-breakpoint-down(lg){
    #about-hero{
        .heading{
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
            h1{
                max-width: unset;
                margin-bottom: 2rem;
            }
            .divider{
                display: none;
            }
            img{
                margin-left: unset;
                margin-bottom: 1rem;
            }
        }
        .wrapper{
            flex-direction: column-reverse;
            .trailing{
                margin-bottom: 2rem;
            }
        }
    }
    #breakdown{
        .wrapper{
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 3rem;
            .leading, .trailing{
                width: 50%;
            }
            .leading{
                h2{
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                .prize{
                    flex-direction: column;
                    align-items: flex-start;
                    h1{
                        font-size: 3rem;
                        line-height: 3.5rem;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(md){
    #about-hero{
        background-size: 200%;
        background: radial-gradient(100% 100% at 50% 0%, rgba(19, 23, 25, 0) 0%, #131719 100%), url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
        background-position: center top;
        padding-top: 7rem;
    }
    #launch-season{
        padding: 3rem 0;
        .heading{
            text-align: center;
            flex-direction: column;
            h2{
                margin-bottom: 1rem;
                &:last-child{
                    margin-left: unset;
                    margin-bottom: unset;
                }
            }
        }
    }
    #how-to-qualify{
        .leading{
            .wrapper{
                padding: 1rem;
            }
        }
    }
}
@include media-breakpoint-down(sm){
    #about-hero{
        .wrapper{
            .leading{
                &::before, &::after{
                    content: unset;
                }
            }
        }
    }
    #launch-season{
        .heading{
            h2{
                &:last-child{
                    padding: 1rem;
                }
            }
        }
        .details{
            &::before, &::after{
                content: unset;
            }
        }
    }
    #how-to-qualify{
        .divider{
            height: 100%;
            margin: 0 2rem;
        }
    }
    #breakdown{
        .title{
            h1{
                font-size: 2rem;
            }
        }
        .wrapper{
            flex-direction: column;
            .leading, .trailing{
                width: 100%;
            }
            .leading{
                h2{
                    font-size: 2rem;
                    line-height: 2.5rem;
                }
                .prize{
                    h1{
                        font-size: 3.5rem;
                        line-height: 4rem;
                    }
                }
            }
        }
    }
}
</style>
