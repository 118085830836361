<template>
    <div class="teams">
		<div class="container">
			<div class="teams-wrapper">
				<h1>Pro Series Teams</h1>
				<div class="divider"></div>
            	<v-button to="standings" class="orange">Standings</v-button>
			</div>
			<div class="row">
                <team-card v-for="team in featuredTeams" :key="team.id" :team="team"/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['teams']),
        featuredTeams(){
            return this.teams ? this.teams.filter(function(t){ return t.featured == true }) : [];
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.teams{
	padding: 4rem 0;
	.teams-wrapper{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 4rem;
		h1{
			margin-right: 3rem;
		}
		a{
			margin-left: 2rem;
		}
		.divider{
			position: relative;
			display: flex;
			flex-grow: 1;
			height: 2px;
			background: $orange;
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: $orange;
			}
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: $orange;
			}
			&.blue{
				background: $blue;
				&::before{
					background: $blue;
				}
				&::after{
					background: $blue;
				}
			}
		}
		.select{
			margin-left: 2rem;
		}
	}
}
@include media-breakpoint-down(lg){
	.teams{
		.teams-wrapper{
			 margin-bottom: 1.5rem;
			.divider{
				display: none;
			}
		}
	}
}
@include media-breakpoint-down(md){
	.teams{
		padding: 3rem 0 1.5rem;
		.teams-wrapper{
			flex-direction: column;
			justify-content: center;
			h1{
				margin-bottom: .75rem;
				margin-right: unset;
				text-align: center;
			}
			a{
				margin-left: unset;
			}
			.select{
				margin-left: unset;
			}
		}
	}
}
</style>
