<template>
<div id="about-wrapper">
	<section id="about">
		<div class="container">
			<div class="row parent">
				<div class="col-xl-6 col-lg-8 leading">
					<h2>{{ content['index.about.title'] }}</h2>
					<div class="wrapper">
						<div class="text-wrapper" v-html="body">

						</div>
						<div class="highlights">
							<div class="highlight">
								<h1>{{ content['index.about.stat1.value'] }}</h1>
								<h3>{{ content['index.about.stat1.text'] }}</h3>
							</div>
							<div class="highlight">
								<h1>{{ content['index.about.stat2.value'] }}</h1>
								<h3>{{ content['index.about.stat2.text'] }}</h3>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-lg-4 trailing">
					<img src="https://cdn.splitgateproseries.com/pro-series/elements/about_character.png" alt="">
				</div>
			</div>
		</div>
	</section>
	<section id="prizing">
		<div class="container">
			<div class="row prizes">
				<div class="col-lg-6 leading">
					<div class="prize">
						<h1>{{ content['index.about.leftbox.value'] }}</h1>
						<h3>{{ content['index.about.leftbox.text'] }}</h3>
					</div>
				</div>
				<div class="col-lg-6 trailing">
					<div class="prize final">
						<h1>{{ content['index.about.rightbox.value'] }}</h1>
						<h3>{{ content['index.about.rightbox.text'] }}</h3>
					</div>
					<div class="wrapper">
                        <v-button class="blue" to="about">Full Overview</v-button>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
</template>

<script>
import { mapState } from 'vuex';
import marked from 'marked';
export default {
    computed: {
        ...mapState([
            'content'
        ]),
        body(){
            return this.content['index.about.body'] ? marked(this.content['index.about.body']) : '';
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
#about-wrapper{
	background: radial-gradient(54.28% 54.28% at 62.43% 28.7%, rgba(19, 23, 25, 0) 0%, #131719 100%), url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
	background-size: cover;
	background-position: center top;
	padding-top: 7.5rem;
	#about{
		.parent{
			.leading{
				h2{
					margin-bottom: 3rem;
				}
				.wrapper{
					.text-wrapper{
						position: relative;
						padding-left: 4.875rem;
						border-left: solid 2px $orange;
						border-bottom: solid 2px $orange;
						border-radius: 0 0 0 1rem;
						padding-bottom: 3rem;
						&:before{
							content: '';
							position: absolute;
							top: 0;
							left: -5px;
							height: .5rem;
							width: .5rem;
							border-radius: 50%;
							background: $orange;
						}
						&:after{
							content: '';
							position: absolute;
							bottom: -5px;
							left: 4.275rem;
							height: .5rem;
							width: .5rem;
							border-radius: 50%;
							background: $orange;
						}
					}
					p{
						margin-bottom: 5rem;
						font-weight: 500;
					}
					.highlights{
						margin-top: -4rem;
						margin-left: 4.875rem;
						background: $primary-black;
						padding: 2rem;
						position: relative;
						display: flex;
						align-items: center;
						.highlight{
							display: flex;
							flex-direction: column;
							align-items: center;
							margin-right: 2rem;
							&:last-child{
								margin-right: unset;
							}
							h1{
								@media screen and (max-width: 1400px){
									font-size: 3.4rem;
								}
							}
							h3{
								color: $blue;
							}
						}
					}
				}
			}
			.trailing{
				margin-top: -9rem;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				img{
					width: 85%;
					object-fit: contain;
				}
			}
		}
	}
	#prizing{
		padding: 5rem 0;
		position: relative;
		&::before{
			content: '';
			position: absolute;
			z-index: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 52%;
			background: linear-gradient(90deg, #363B3F 0%, #23272A 100%),;
			border-top: solid 1px #3D4246;
			border-bottom: solid 1px #3D4246;
		}
		.prize{
			position: relative;
			background: $primary-black;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 2rem;
			border: solid 2px $blue;
			border-radius: 1rem;
			h1{
				font-size: 5rem;
				line-height: 8rem;
			}
			h3{
				color: $blue;
			}
			&.final{
				background: $orange;
				border: solid 2px $orange;
				h3{
					color: unset;
				}
			}
		}
		.prizes{
			position: relative;
			z-index: 1;
			&::before{
				content: '';
				position: absolute;
				z-index: -1;
				bottom: 1.6rem;
				right: 12rem;
				border-bottom: solid 2px $blue;
				height: 80%;
				width: 46rem;
			}
			&::after{
				content: '';
				position: absolute;
				z-index: -1;
				bottom: 1.5rem;
				left: .8rem;
				border-top: solid 2px $blue;
				border-right: solid 2px $blue;
				height: 80%;
				width: 19.8rem;
				border-radius: 0 1rem 0 0;
			}
			.leading{
				padding-top: 7.5rem;
				position: relative;
				.prize{
					&:before{
						content: '';
						position: absolute;
						top: -4.9rem;
						left: -5px;
						height: .5rem;
						width: .5rem;
						border-radius: 50%;
						background: $blue;
					}
				}
			}
			.trailing{
				.prize{
					margin-bottom: 4.5rem;
				}
				.wrapper{
					position: relative;
					display: flex;
					justify-content: flex-end;
					&:before{
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 11rem;
						height: .5rem;
						width: .5rem;
						border-radius: 50%;
						background: $blue;
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(xxl){
	#prizing{
		.prizes{
			&::before{
				width: 41.5rem;
			}
			&::after{
				width: 17rem;
			}
		}
	}
}
@include media-breakpoint-down(xl){
	#about-wrapper{
		overflow: hidden;
		#about{
			margin-bottom: 4rem;
			.parent{
				.trailing{
					position: relative;
					margin-top: unset;
					img{
						position: absolute;
						top: -4rem;
						right: -22vw;
						width: 180%;
					}
				}
			}
		}
	}
	#prizing{
		.prizes{
			&::before{
				width: 33rem;
			}
			&:after{
				width: 14.3rem;
			}
		}
	}
}
@include media-breakpoint-down(lg){
	#about-wrapper{
		padding-top: 3rem;
		#about{
			.parent{
				flex-direction: column-reverse;
				.leading{
					position: relative;
					z-index: 2;
					margin-top: -26rem;
				}
				.trailing{
					img{
						position: static;
						width: 100%;
					}
					&::before{
						content: '';
						position: absolute;
						z-index: 1;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: linear-gradient(180deg, rgba(19, 23, 25, 0) 0%, #131719 50%);
					}
				}
			}
		}
		#prizing{
			padding-top: unset;
			.prizes{
				.leading{
					padding-top: unset;
					.prize{
						margin-bottom: 2rem;
						&::before{
							content: unset;
						}
					}
				}
				.trailing{
					.prize{
						margin-bottom: 2rem;
					}
					.wrapper{
						justify-content: center;
						&::before{
							content: unset;
						}
					}
				}
				&::before, &::after{
					content: unset;
				}
			}
			&::before{
				height: 43%;
			}
		}
	}
}
@include media-breakpoint-down(md){
	#about-wrapper{
		overflow: hidden;
		#about{
			.parent{
				.leading{
					margin-top: -20rem;
					h2{
						margin-bottom: 1.5rem;
					}
					.wrapper{
						.text-wrapper{
							padding-left: unset;
							border: unset;
							margin-bottom: 1.5rem;
							&::before, &::after{
								content: unset;
							}
						}
						.highlights{
							margin-left: unset;
							padding: unset;
							background: unset;
							justify-content: center;
						}
					}
				}
				.trailing{
					img{
						width: 105%;
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(sm){
	#about-wrapper{
		#about{
			.parent{
				.leading{
					margin-top: max(-95vw, -20rem);
					.wrapper{
						.highlights{
							@media screen and (max-width: 334px){
								flex-direction: column;
							}
							.highlight{
								@media screen and (max-width: 334px){
									margin-right: unset;
									margin-bottom: 1rem;
								}
								&:last-child{
									margin-bottom: unset;
								}
								h1{
									font-size: 2.75rem;
									line-height: 3.25rem;
								}
								h3{
									font-size: 1rem;
								}
							}
						}
					}
				}
				.trailing{
					img{
						width: 180%;
						position: relative;
						top: 0;
						right: unset;
						left: -2rem;
						&::before{
							height: 80%;
						}
					}
				}
			}
		}
		#prizing{
			.prize{
				h1{
					font-size: 4.5rem;
					line-height: 5rem;
				}
				h3{
					text-align: center;
				}
			}
		}
	}
}
</style>
