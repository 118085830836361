<template>
    <div class="button disabled" v-if="href && disabled || to && disabled"><slot></slot></div>
    <a class="button" v-else-if="href" :href="href" target="_blank"><slot></slot></a>
    <router-link class="button" v-else-if="to" :to="{ name: to }"><slot></slot></router-link>
    <button class="button" v-else :disabled="disabled"><slot></slot></button>
</template>

<script>
export default {
    props: ['to','href','disabled','class']
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
a, button, div{
    background: $orange;
    color: #fff;
    border-radius: .5rem;
    padding: .75rem 1.5rem;
    text-decoration: unset;
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    font-size: .825rem;
    position: relative;
    isolation: isolate;
    overflow: hidden;
    border: solid 2px $orange;
    transition: all 600ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
    display: flex;
    align-items: center;
    justify-content: center;
    &.blue{
        background: $blue;
        border: solid 2px $blue;
    }
    &.red{
        background: $red;
        border: solid 2px $red;
    }
    &.secondary{
        background: unset;
        color: #fff;
        border: solid 2px $orange;
        transition: color 600ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
        &::before{
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: $orange;
            transform: scaleX(0%);
            transform-origin: left;
            transition: all 600ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
        }
        &:hover{
            &:before{
                width: 100%;
                transform: scaleX(100%);
            }
        }
        &.blue{
            border: solid 2px $blue;
            &::before{
                background: $blue;
            }
        }
        &.red{
            border: solid 2px $red;
            &::before{
                background: $red;
            }
        }
    }
    &:disabled, &.disabled{
        border: solid 2px #3D4246!important;
        background: $primary-black;
        color: #6B6B6B;
        &.event{
            background: $light-grey;
        }
        &.secondary{
            &::before{
                content: unset;
                &:hover{
                    transform: unset;
                }
            }
        }
    }
    &.leaderboard{
        padding: 1rem .75rem;
        font-size: 1.185rem;
        background: #383D41;
        color: #898B8D;
        border: unset;
        transition: all 600ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
        &.active{
            background: $orange;
            color: #fff;
        }
    }
}
</style>
