<template>
    <div class="select-wrapper">
        <select :value="modelValue" @input="this.$emit('update:modelValue', $event.target.value)">
            <slot></slot>
        </select>
        <i class="fas fa-caret-down"></i>
    </div>
</template>

<script>
export default {
    props: ['modelValue','label','disabled'],
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.select-wrapper{
    position: relative;
    select{
        // -webkit-appearance: none;
        // -moz-appearance: none;
        // appearance: none;
        width: 100%;
        background: $primary-black !important;
        color: #fff;
        font-size: .825rem;
        font-weight: 800;
        border-radius: .5rem;
        padding: .75rem 3rem .75rem 1.5rem;
        text-transform: uppercase;
        border: solid 2px $blue;
        appearance: unset;
        outline: unset;
        option {
            font-size:10px !important;
        }
    }
    i{
        position: absolute;
        font-size: 1.25rem;
        right: 1.25rem;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
    }
}
@include media-breakpoint-down(md){
    .select-wrapper{
        select{
            font-size: 1rem;
        }
        i{
            font-size: 1rem;
        }
    }
}
</style>
