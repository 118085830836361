<template>
    <div class="wrapper">
        <div class="header">
            <a-input class="search" id="search" placeholder="Search" type="search" icon="far fa-search" v-model='search'/>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" v-for="header in headers" :key="header.value">{{ header.name }}</th>
                    <th scope="col">Edit</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filteredList" :key="row.slug">
                    <td v-for="header in headers" :key="header.value">{{ row[header.value] }}</td>

                    <td style="display:flex;justify-content:flex-end;">
                        <a-button class="small secondary" to="admin.team" :params="{slug: row.slug}">View &amp; Edit</a-button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th scope="col" v-for="header in headers" :key="header.value">{{ header.name }}</th>
                    <th scope="col">Edit</th>
                </tr>
            </tfoot>
        </table>
        <div class="footer">
            <p>Showing {{ offset+1 }}-{{ offset+parseInt(limit) }} of {{ data.length }} entries</p>
            <!-- <ul>
                <li><i class="far fa-chevron-left"></i></li>
                <li v-for="i in pages" @click="pageChange(i-1)" :key="i">{{ i }}</li>
                <li><i class="far fa-chevron-right"></i></li>
            </ul> -->
        </div>
    </div>
</template>

<script>
export default {
    props: ['data','headers'],
    data(){
        return {
            search: '',
            limit: 10,
            page: 0
        }
    },
    computed: {
        filteredList() {
            return this.data.filter(row => {
                return row.name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
        paginatedData(){
            const start = this.offset,
                  end = start + parseInt(this.limit);
            return this.filteredList.slice(start, end);
        },
        offset(){
            return this.page * parseInt(this.limit)
        },
        pages() {
            return Math.ceil(this.filteredList.length / parseInt(this.limit));
        },
        isPreviousButtonDisabled() {
            return this.page === 1
        },
        isNextButtonDisabled() {
            return this.page === this.data.length
        }
    },
    watch: {
        search: function() {
            if(this.page > 0) {
                this.page = 0
            }
        }
    },
    methods: {
        pageChange(value) {
            switch (value) {
            case 'next':
                this.page += 1
                break
            case 'previous':
                this.page -= 1
                break
            default:
                this.page = value
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.wrapper{
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    .table{
        thead{
            background: $bg1;
            font-size: .8rem;
            text-transform: uppercase;
            color: $light-foreground;
            border-radius: .25rem;
            width: 100%;
            tr{
                th{
                    padding: .8rem;
                    border: unset;
                }
            }
        }
        tbody{
            tr{
                td{
                    position: relative;
                    padding: .8rem;
                    border-bottom: $light-bg solid 0.1rem;
                    div{
                        display: flex;
                        height: 100%;
                        width: 100%;
                        align-items: center;
                        position: absolute;
                        align-items: center;
                        top: 0;
                    }
                }
            }
        }
    }
    .footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: $bg1;
        padding: .5rem .5rem;
        p{
            margin: unset;
        }
        ul{
            display: flex;
            list-style-type: none;
            margin: unset;
            li{
                padding: .375rem .75rem;
                i{
                    font-size: .75rem;
                }
            }
        }
    }
}
</style>
