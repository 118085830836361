<template>
    <div class="button disabled" v-if="href && disabled || to && disabled"><slot></slot></div>
    <a class="button" v-else-if="href" :href="href"><slot></slot></a>
    <router-link class="button" v-else-if="to" :to="{ name: to, params: params || null }"><slot></slot></router-link>
    <button class="button" v-else :disabled="disabled" :type="type"><slot></slot></button>
</template>

<script>
export default {
    props: ['params','to','href','disabled','type']
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.button{
    text-decoration: unset;
    font-size: 1rem;
    padding: .75rem 1.5rem;
    border-radius: .25rem;
    background: $dark-foreground;
    color: #fff;
    outline: unset;
    border: unset;
    &.secondary{
        background: $light-foreground;
    }
    &.disabled{
        background: unset;
        color: $light-foreground;
    }
    &.small{
        font-size: .8rem;
        padding: .5rem 1rem;
    }
}
</style>
