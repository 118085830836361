<template>
    <main>
        <section id="news">
            <div class="container">
                <div class="heading">
                    <h1>Recent News</h1>
                    <div class="pagination">
                        <v-button :disabled="previousButtonDisabled" @click="pageChange('previous')">Prev</v-button>
                        <v-button :disabled="nextButtonDisabled" @click="pageChange('next')">Next</v-button>
                    </div>
                </div>
                <div class="row wrapper">
                    <article-card v-for="article in paginatedArticles" :key="article.slug" :article="article"/>
                </div>
            </div>
            <div class="pagination mobile">
                <v-button :disabled="previousButtonDisabled" @click="pageChange('previous')">Prev</v-button>
                <v-button :disabled="nextButtonDisabled" @click="pageChange('next')">Next</v-button>
            </div>
        </section>
    </main>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data(){
        return {
            limit: 9,
            page: 0
        }
    },
    computed: {
        ...mapState([
            'articles'
        ]),
        filteredArticles(){
            if(this.articles.length){
                return this.articles;
            }else{
                return [];
            }
        },
        pages(){
            return Math.ceil(this.filteredArticles.length / parseInt(this.limit));
        },
        offset(){
            return this.page * parseInt(this.limit)
        },
        paginatedArticles(){
            const start = this.offset,
                  end = start + parseInt(this.limit);
            return this.filteredArticles.slice(start, end);
        },
        previousButtonDisabled() {
            return this.page <= 0
        },
        nextButtonDisabled(){
            return this.page === this.pages-1
        }
    },
    methods: {
        pageChange(page) {
            if(page == 'next'){
                if(!this.nextButtonDisabled){
                    this.page += 1;
                }
            }else if(page == 'previous'){
                if(!this.previousButtonDisabled){
                    this.page -= 1;
                }
            }else{
                this.page = page;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
section#news{
    background: radial-gradient(100% 100% at 50% 0%, rgba(19, 23, 25, 0) 0%, #131719 100%), url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
	background-size: 100%;
    background-position: center top;
    padding-top: 9rem;
    .heading{
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1{
            margin-bottom: unset;
        }
    }
    .pagination{
        display: flex;
        justify-content: center;
        &.mobile{
            display: none;
        }
        button{
            margin-right: 1rem;
            &:last-child{
                margin-right: unset;
            }
        }
    }
}
@include media-breakpoint-down(md){
    section#news{
        background-size: 200%;
        padding-top: 7rem;
        position: relative;
        &::before{
            height: 32%;
        }
        .heading{
            flex-direction: column;
            justify-content: center;
            margin-bottom: 1.5rem;
            .pagination{
                display: none;
            }
        }
        .pagination{
            &.mobile{
                display: flex;
                position: sticky;
                bottom: 0;
                left: 0;
                button{
                    width: 50%;
                    margin-right: .25rem;
                    &:last-child{
                        margin-right: unset;
                    }
                }
            }
        }
    }
}
</style>
