<template>
    <div class="col-lg-4 breakdown-wrapper">
        <div class="leading">
            <h2>{{ title }}</h2>
            <div class="prize">
                <h1>{{ prize }}</h1>
            </div>
        </div>
        <div class="trailing">
            <div class="placement-prize">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'prize']
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.breakdown-wrapper{
    .leading{
        margin-bottom: 1.5rem;
        color: #fff;
        h2{
            font-weight: 200;
        }
        .prize{
            display: flex;
            align-items: flex-end;
            h1{
                color: $orange;
                margin-right: 1rem;
            }
            p{
                margin-bottom: unset;
            }
        }
    }
    .trailing{
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;
        :deep(.placement-prize){
            display: flex;
            &:first-child{
                h2{
                    &:first-child{
                        font-size: 1.825rem;
                        line-height: 1.4;
                    }
                }
            }
            .placement,.prize{
                h2{
                    font-size: 1.25rem;
                    line-height: 1.4;
                    margin-bottom: 1rem;
                    font-weight: 200;
                }
            }
            .placement{
                color: $blue;
                padding-right: 2rem;
                border-right: solid 2px #3A3E42;
                width: 6rem;
            }
            .prize{
                padding-left: 2rem;
            }
            a{
                transition: color 0.2s;
                color: $blue;
                text-decoration: none;
                &:hover{
                    color: #fff;
                }
            }
        }
    }
}
@include media-breakpoint-down(lg){
    .breakdown-wrapper{
        display: flex;
        justify-content: space-between;
    }
}
@include media-breakpoint-down(md){
    .breakdown-wrapper{
        flex-direction: column;
        .leading{
            h2{
                font-size: 1rem;
            }
        }
    }
}
</style>