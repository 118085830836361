<template>
    <footer>
        <div class="container">
            <div class="footer-row">
                <div class="leading">
                    <ul>
                        <li>
                            <router-link to="standings">Standings</router-link>
                        </li>
                         <li>
                            <router-link to="schedule">Schedule</router-link>
                        </li>
                         <li>
                            <router-link to="news">News</router-link>
                        </li>
                    </ul>
                    <img src="https://cdn.splitgateproseries.com/pro-series/logos/sps.svg" alt="Splitgate Pro Series">
                    <div class="socials">
                        <div class="wrapper">
                            Stay Connected
                            <ul>
                                <li>
                                    <a target="_blank" href="https://twitter.com/SGEsports"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a target="_blank" href="http://discord.gg/wg6uCdDPhx"><i class="fab fa-discord"></i></a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.twitch.tv/splitgate"><i class="fab fa-twitch"></i></a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.youtube.com/channel/UCdUA8I1gJ606kBa9MfTZIyw"><i class="fab fa-youtube"></i></a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://reddit.com/r/splitgate"><i class="fab fa-reddit-alien"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="trailing">
                    <ul>
                        <li>
                            <p>© {{ new Date().getFullYear() }} Splitgate Pro Series. All Rights Reserved.</p>
                        </li>
                    </ul>
                    <div class="logos">
                        <img src="https://cdn.splitgateproseries.com/pro-series/logos/1047.png" alt="1047 Games Logo">
                        <img src="https://cdn.splitgateproseries.com/pro-series/logos/beyond.svg" alt="Beyond Entertainment Logo">
                    </div>
                     <ul>
                        <li>
                            <a href="https://playbeyond.com/pages/tos" target="_blank">Terms &amp; Conditions</a>
                        </li>
                        <li>
                            <a href="https://playbeyond.com/pages/privacy" target="_blank">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
footer{
    padding-bottom: 2.5rem;
    margin-top: 5rem;
    overflow: hidden;
    .footer-row{
        ul{
            padding: unset;
            margin: unset;
            list-style-type: none;
            display: flex;
            align-items: center;
            li{
                padding: unset;
                margin-right: 2rem;
                &:last-child{
                    margin-right: unset;
                }
                a{
                    touch-action: manipulation;
                    user-select: none;
                    flex-direction: column;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    font-size: .825rem;
                    font-weight: 500;
                    text-decoration: unset;
                    text-transform: uppercase;
                    color: #fff;
                }
            }
        }
        .leading, .trailing{
            display: flex;
            justify-content: space-between;
            align-items: center;
            ul{
                flex-grow: 1;
                flex-basis: 0;
                &:last-child{
                    justify-content: flex-end;
                }
            }
            img{
                height: 3rem;
            }
        }
        .leading{
            .socials{
				display: flex;
                flex-grow: 1;
                flex-basis: 0;
				justify-content: flex-end;
                align-items: center;
                .wrapper{
                    display: flex;
                    align-items: center;
                    font-size: .825rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    ul{
                        padding: unset;
                        margin: unset;
                        list-style-type: none;
                        display: flex;
                        align-items: center;
                        margin-left: 1.5rem;
                        li{
                            margin-right: 1rem;
                            &:last-child{
                                margin-right: unset;
                            }
                            a{
                                i{
                                    color: #fff;
                                    font-size: 1.25rem;
                                }
                            }
                        }
                    }
                }
			}
        }
        .trailing{
            .logos{
                img{
                    height: unset;
                    height: 3rem;
                    margin-right: 2rem;
                    &:last-child{
                        margin-right: unset;
                        height: 2rem;
                    }
                }
            }
            ul{
                li{
                    p{
                        font-size: .825rem;
                        font-weight: 500;
                    }
                    a{
                        color: #AEB3B9;
                        font-size: .825rem;
                        font-weight: 500;
                        text-transform: unset;
                        font-style: unset;
                    }
                }
            }
        }
        .divider{
            position: relative;
            background: linear-gradient(90deg, #FF5B00 0%, #4CC5F3 100.21%);
            margin: 2rem 0;
            width: 100%;
            height: 2px;
            &:before{
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                height: .5rem;
                width: .5rem;
                border-radius: 50%;
                background: $orange;
            }
            &:after{
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                height: .5rem;
                width: .5rem;
                border-radius: 50%;
                background: $blue;
            }
        }
    }
}
@include media-breakpoint-down(lg){
    footer{
        .footer-row{
            .leading{
                ul{
                    li{
                        margin-right: 1rem;
                        a{
                            font-size: 1rem;
                        }
                    }
                }
                .socials{
                    .wrapper{
                        font-size: 1rem;
                    }
                }
                img{
                    display: none;
                }
            }
            .trailing{
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: flex-start;
                ul{
                    margin-bottom: 1rem;
                }
                .logos{
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
@include media-breakpoint-down(md){
    footer{
        padding-bottom: 1rem;
        margin-top: 2.5rem;
    }
}
@include media-breakpoint-down(sm){
    footer{
        .footer-row{
            .leading{
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                ul{
                    margin-bottom: 1rem;
                }
            }
            .trailing{
                .logos{
                    img{
                        height: 2rem;
                        margin-right: 1rem;
                        &:last-child{
                            height: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
