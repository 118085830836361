<template>
    <main>
        <section id="standings">
            <div class="container">
                <div class="heading">
                    <h1>Season Standings</h1>
                    <input class="col-4" type="text" name="" id="" placeholder="Search..." v-model="search">
                </div>
                <div id="leaderboard">
                    <div class="row">
                        <div class="col-xxl-2 col-lg-3">
                            <div class="options">
                                <div class="option">
                                    <label for="">Season</label>
                                    <v-select v-model="season">
                                        <option :value="season.slug" v-for="season in seasons" :key="season.slug">{{ season.name }}</option>
                                    </v-select>
                                </div>
                                <transition name="slide-fade">
                                    <div class="option" v-if="selectedSeason.slug == 'launch-season'">
                                        <label for="">Event</label>
                                        <v-select v-model="event">
                                            <option value="">Total</option>
                                            <template v-for="event in selectedSeason.events" :key="event.slug">
                                                <option :value="event.slug" v-if="event.standings">{{ event.name }}</option>
                                            </template>
                                        </v-select>
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-lg-8 offset-lg-1">
                            <div class="labels" v-if="selectedSeason.slug == 'launch-season'">
                                <label for="">#</label>
                                <label for="">Player</label>
                                <label for="">Points</label>
                            </div>
                            <div class="labels team" v-else>
                                <label for="">#</label>
                                <label for="">Team</label>
                                <label for="">Game Win %</label>
                                <label for="">Record</label>
                            </div>
                            <div class="loading" v-if="loadingStandings">
                                <i class="fas fa-circle-notch fa-spin"></i>
                                Loading Standings
                            </div>
                            <div class="empty" v-else-if="!paginatedStandings.length">
                                <i class="fas fa-exclamation-circle"></i>
                                No Standings Found
                            </div>
                            <div class="players" v-else>
                                <leaderboard-item :class="item.position == 1 ? 'primary' : item.position > 1 && item.position < 9 ? 'secondary' : ''" v-for="item in paginatedStandings" :key="item.position" :item="item"/>
                            </div>
                            <div class="pagination" v-if="pages > 1">
                                <v-button :class="[i == page+1 ? 'active' : '', 'leaderboard']" v-for="i in pages" :key="i" @click="pageChange(i-1)">{{ ('0'+i).slice(-2) }}</v-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
    data() {
        return {
            loadingStandings: true,
            season: 'fall-2022',
            event: '',
            search: '',
            standings: [],
            limit: 20,
            page: 0
        };
    },
    computed: {
        ...mapState([
            'seasons'
        ]),
        selectedSeason(){
            let t = this;
            return this.seasons.filter(function(s){ return s.slug == t.season; })[0] || [];
        },
        filteredStandings() {
            return this.standings.filter(row => {
                return row.name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
        pages(){
            return Math.ceil(this.filteredStandings.length / parseInt(this.limit));
        },
        offset(){
            return this.page * parseInt(this.limit)
        },
        paginatedStandings(){
            const start = this.offset,
                  end = start + parseInt(this.limit);
            return this.filteredStandings.slice(start, end);
        },
        previousButtonDisabled() {
            return this.page <= 0
        },
        nextButtonDisabled(){
            return this.page === this.pages-1
        }
    },
    methods: {
        getStandings:function(){
            this.loadingStandings = true;
            this.standings = [];
            axios.get('/api/standings',{
                params: {
                    season: this.season,
                    event: this.event
                }
            }).then(r => {
                if(r.status == 200){
                    this.standings = r.data;
                    this.loadingStandings = false;
                }
            }).catch(e => {
                console.error(e);
            });
        },
        pageChange(page) {
            if(page == 'next'){
                if(!this.nextButtonDisabled){
                    this.page += 1;
                }
            }else if(page == 'previous'){
                if(!this.previousButtonDisabled){
                    this.page -= 1;
                }
            }else{
                this.page = page;
            }
        }
    },
    watch: {
        'season': function() {
            this.event = '';
            this.page = 0;
            this.getStandings();
        },
        'event': function() {
            this.page = 0;
            this.getStandings();
        },
        'search': function() {
            this.page = 0;
        },
    },
    mounted(){
        this.getStandings();
    }
};
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
#standings{
	background: radial-gradient(100% 100% at 50% 0%, rgba(19, 23, 25, 0) 0%, #131719 100%), url('https://cdn.splitgateproseries.com/pro-series/backgrounds/background.jpg') no-repeat;
	background-size: 100%;
    background-position: top center;
    padding-top: 9rem;
    .heading{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
        h1{
            text-align: center;
        }
        input{
            font-size: 1rem;
            text-transform: uppercase;
            padding: .75rem 1.5rem;
            outline: none;
            border: solid 2px $light-grey;
            border-radius: .5rem;
            background: $primary-black;
            color: #fff;
            transition: all $transition;
            &::placeholder{
                color: #383D41;
            }
            &:focus{
                border: solid 2px $blue;
            }
        }
    }
    #leaderboard{
        .heading{
            padding: 0 .5rem;
            display: flex;
            align-items: center;
        }
        .pagination{
            display: flex;
            justify-content: center;
            padding-top: 2rem;
            button{
                margin-right: 1rem;
                &:last-child{
                    margin-right: unset;
                }
            }
        }
        .options{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .option{
                display: flex;
                flex-direction: column;
                margin-bottom: 1.5rem;
                flex: 1;
                transition: all $transition;
            }
        }
        .labels{
            display: flex;
            align-items: center;
            label{
                display: flex;
                margin-bottom: 1rem;
                text-transform: uppercase;
                font-size: .825rem;
                &:first-child{
                    margin-right: 4rem;
                }
                &:last-child{
                    justify-content: flex-end;
                    flex-grow: 1;
                }
            }
            &.team{
                label{
                    &:nth-child(2){
                        flex-grow: 1;
                    }
                    &:last-child{
                        flex-grow: unset;
                        justify-content: unset;
                        margin-left: 3.75rem;
                    }
                }
            }
        }
        .loading, .empty{
            background: $light-grey;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            border-radius: .5rem;
            border: solid 1px #3D4246;
            font-style: italic;
            text-transform: uppercase;
            i{
                margin-right: .5rem;
            }
        }
    }
}
@include media-breakpoint-down(lg){
    #standings{
        padding-top: 7rem;
        padding-bottom: 2rem;
        .heading{
            flex-direction: column;
            justify-content: center;
            margin-bottom: 1rem;
            h1{
                margin-bottom: 1.5rem;
            }
            input{
                width: 100%;
            }
        }
        #leaderboard{
            .options{
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 2rem;
                .option{
                    margin-bottom: unset;
                }
            }
            label{
                margin-bottom: .5rem;
            }
        }
    }
}
@include media-breakpoint-down(md){
    #standings{
        background-size: 200%;
        padding-bottom: unset;
        #leaderboard{
            .labels{
                label{
                    &:first-child{
                        margin-right: 2.25rem;
                    }
                }
                &.team{
                    label{
                        &:last-child{
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
