<template>
    <a-section-header>
        <template v-slot:title>
            Standings
        </template>
        <template v-slot:button>
            <a-select v-model="season">
                <option :value="season.slug" v-for="season in seasons" :key="season.slug">{{ season.name }}</option>
            </a-select>
            <a-select v-model="event">
                <option value="">Total</option>
                <option :value="event.slug" v-for="event in selectedSeason.events" :key="event.slug">{{ event.name }}</option>
            </a-select>
            <a-button @click="saveStandings">Save</a-button>
        </template>
    </a-section-header>

    <div class="row parent">
        <div id="teams" class="col-md-3 teams">
            <h3>Teams</h3>
            <input class="search" placeholder="Search Teams" type="text" v-model="searchTeams">
            <div class="loading" v-if="loadingTeams"><i class="fas fa-circle-notch fa-spin"></i> Loading</div>
            <draggable
                class="list-group"
                :list="filteredTeams"
                group="people"
                @change="log"
                itemKey="name" v-else>
                <template #item="{ element, index }">
                <div class="list-group-item">{{ element.name }} </div>
                </template>
            </draggable>
        </div>

        <div id="players" class="col-md-3 players">
            <h3>Players</h3>
            <input class="search" placeholder="Search Players" type="text" v-model="searchPlayers">
            <div class="wrapper">
                <div class="loading" v-if="loadingPlayers"><i class="fas fa-circle-notch fa-spin"></i> Loading</div>
                <draggable
                    class="list-group"
                    :list="filteredPlayers"
                    group="people"
                    @change="log"
                    itemKey="name" v-else>
                    <template #item="{ element, index }">
                    <div class="list-group-item">{{ element.name }}</div>
                    </template>
                </draggable>
            </div>
        </div>

        <div class="col-md-6 standings" v-if="pointsSeason">
            <h3>Standings</h3>
            <p>Standings are handled automatically by the weekly events points.</p>
        </div>
        <div class="col-md-6 standings" v-else>
            <h3>Standings</h3>
            <div class="labels">
                <label>#</label>
                <label>Player</label>
                <label>Points</label>
            </div>
            <div class="loading" v-if="loadingStandings"><i class="fas fa-circle-notch fa-spin"></i> Loading</div>
            <draggable
                class="list-group"
                :list="standings"
                group="people"
                @change="log"
                itemKey="name" v-else>
                <template #item="{ element, index }">
                    <div class="list-group-item">
                        <div class="wrapper">
                            <p>{{ index+1 }}</p>
                            <p>{{ element.name }}</p>
                        </div>
                        <div class="wlp" v-if="!event">
                            <label>W</label>
                            <input type="number" v-model="element.win">
                            <label>L</label>
                            <input type="number" v-model="element.loss">
                            <label>%</label>
                            <input v-model="element.percentage">
                        </div>
                        <input type="text" v-model="element.points" v-if="event && event != ''">
                    </div>
                </template>
            </draggable>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-6">
            {{ standings }}
        </div>
    </div> -->
</template>

<script>
import draggable from "vuedraggable";
export default {
   components: {
      draggable
   },
   data() {
      return {
            seasons: [],
            loadingTeams: true,
            loadingPlayers: true,
            loadingStandings: true,
            season: 'launch-season',
            event: '',
            searchTeams: '',
            searchPlayers: '',
            teams: [],
            players: [],
            standings: []
      };
   },
   computed: {
        selectedSeason(){
            let t = this;
            return this.seasons.filter(function(s){ return s.slug == t.season; })[0] || [];
        },
        pointsSeason(){
            return this.selectedSeason.points && !this.event ? true : false;
        },
        filteredTeams() {
            return this.teams.filter(row => {
                return row.name.toLowerCase().includes(this.searchTeams.toLowerCase());
            });
        },
        filteredPlayers() {
            return this.players.filter(row => {
                return row.name.toLowerCase().includes(this.searchPlayers.toLowerCase());
            });
        },
        spaceAbovePlayers() {
            const players = document.getElementById('players');
            console.log(players);
            return players ? players.offsetHeight : 0;
        }
   },
   methods: {
       getSeasons:function(){
            axios.get('/api/admin/seasons').then(s => {
                if(s.status == 200){
                    this.seasons = s.data;
                }
            }).catch(e => {
                console.error(e);
            });
      },
      getPlayers:function(){
            axios.get('/api/admin/players').then(r => {
                if(r.status == 200){
                    this.players = r.data;
                    this.loadingPlayers = false;
                }
            }).catch(e => {
                console.error(e);
            });
      },
      getTeams:function(){
            axios.get('/api/admin/teams').then(r => {
                if(r.status == 200){
                    this.teams = r.data;
                    this.loadingTeams = false;
                }
            }).catch(e => {
                console.error(e);
            });
      },
      getStandings:function(){
          this.loadingStandings = true;
          this.standings = [];
            axios.get('/api/admin/standings',{
                params: {
                    season: this.season,
                    event: this.event
                }
            }).then( r => {
                if(r.status == 200){
                    this.standings = r.data;
                    this.loadingStandings = false;
                }
            }).catch(e => {
                console.error(e);
            });
      },
      saveStandings:function(){
        axios.post('/api/admin/standings',{
            standings: this.standings,
            season: this.season,
            event: this.event
        }).then(response => {
            console.log(response.data);
            alert('Updated Successfully');
        }).catch(error => {
            if (error.response.status === 422) {
                this.errors = error.response.data.errors || {};
                console.log(this.errors);
            }
        });
      },
      // add: function() {
      //     this.list.push({ name: "Juan" });
      // },
      // replace: function() {
      //     this.list = [{ name: "Edgard" }];
      // },
      // clone: function(el) {
      //     return {
      //         name: el.name + " cloned"
      //     };
      // },
		log: function(evt) {
      	window.console.log(evt);
    	}
   },
   watch: {
        'season': function(newValue, oldValue) {
            this.event = '';
            this.getStandings();
            console.log('3');
        },
        'event': function(newValue, oldValue) {
            this.getStandings();
            console.log('2');
        }
   },
   mounted(){
        this.getSeasons();
        this.getPlayers();
        this.getTeams();
        this.getStandings();
   }
};
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.list-group-item{
    border: unset;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform $transition;
    .wrapper{
        display: flex;
        align-items: center;
        p{
            margin-bottom: unset;
            margin-right: 1.5rem;
            &:last-child{
                margin-right: unset;
            }
        }
    }
    .wlp{
        display: flex;
        gap: .5rem;
        input{
            flex-grow: 1;
            width: 4rem;
        }
        label{
            display: flex;
            align-items: center;
        }
    }
    input{
        border-radius: .25rem;
        outline: unset;
        border: unset;
        background: $foreground-5;
        padding: .35rem;
    }
    &:hover{
        transform: translateX(.25rem);
    }
}

.heading{
    padding-bottom: unset !important;
}

.parent{
    position: relative;
    .players,.teams{
        padding-top: 1rem;
        position: sticky;
        top: 0;
        height: calc(100vh - 9.5rem);
        .wrapper{
            overflow-y: scroll;
            height: calc(100% - 7.5rem);
            // height: calc(100vh - 20rem);
            // padding-bottom: 2rem;
        }
    }
}

.standings{
    padding-top: 1rem;
}

.labels{
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;
    border-radius: .25rem;
    background: #fff;
    label{
        color: $text-light;
        font-weight: 800;
        text-transform: uppercase;
        font-size: .825rem;
        &:first-child{
            margin-right: 1.5rem;
        }
        &:nth-child(2){
            margin-right: 15rem;
        }
    }
}

input.search{
    width: 100%;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
    border: solid 1px $foreground-5;
    outline: unset;
    border: unset;
    &::placeholder{
        color: $foreground-15;
    }
}

.loading{
    background: #fff;
    padding: 1rem;
    border-radius: .25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        margin-right: .5rem;
    }
}
@include media-breakpoint-down(xl){
    .labels{
        label{
            &:nth-child(2){
                margin-right: 6rem;
            }
        }
    }
}
@include media-breakpoint-down(md){
    .labels{
        label{
            &:nth-child(2){
                margin-right: 4rem;
            }
        }
    }
}
</style>
