<template>
    <main>
        <a-section-header>
            <template v-slot:title>
                Players
            </template>
            <template v-slot:button>
                <a-button to="admin.player">Add Player</a-button>
            </template>
        </a-section-header>
        <a-table-players :data="players" :headers="[{name:'Name',value:'name'},{name:'Slug',value:'slug'}]"/>
    </main>
</template>

<script>
export default {
    data() {
        return {
                loadingPlayers: true,
                players: []
        };
    },
    methods: {
        getPlayers:function(){
            axios.get('/api/admin/players').then(r => {
                if(r.status == 200){
                    this.players = r.data;
                    this.loadingPlayers = false;
                }
            }).catch(e => {
                console.error(e);
            });
        }
    },
    mounted(){
        this.getPlayers();
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

</style>
