<template>
    <router-view v-slot="{ Component }">
        <component :is="Component"/>
    </router-view>
</template>

<script>
export default {
    mounted() {
        document.body.classList.add('admin');
    },
    unmounted() {
        document.body.classList.remove('admin');
    }
}
</script>

<style lang="scss">
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

body.admin{
    background: $body-background;

    h3{
        padding-bottom: .5rem;
        margin-bottom: 1.5rem;
        border-bottom: solid 1px $foreground-5;
    }

    label{
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: .25rem;
    }
}
</style>
