<template>
    <main>
        <a-section-header>
            <template #title>
                {{ article.title }}
            </template>
            <template #button>
                <a-button @click="setArticle()"><template v-if="processing"><i class="fas fa-circle-notch fa-spin"></i> Saving</template><template v-else>Save</template></a-button>
            </template>
        </a-section-header>
        <div class="row mb-2">
            <div class="col-md-6">
                <a-input label="Title" v-model="article.title" :error="errors.title ? errors.title[0] : null"/>
            </div>
            <div class="col-md-6">
                <a-input label="Slug" v-model="article.slug" :error="errors.slug ? errors.slug[0] : null"/>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-6">
                <a-select v-model="article.user_id" label="Author" :error="errors.user_id ? error.user_id[0] : null" class="mb-2">
                    <option value="" disabled>Select Author</option>
                    <option :value="user.id" v-for="user in users" :key="user.id">{{ user.name_first+' '+user.name_last || ''}}</option>
                </a-select>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-6">
                <DatePicker v-model="dateiso" mode="dateTime" timezone="UTC" is-expanded/>
            </div>
            <div class="col-md-6">
                <img :src="image || article.image" alt="" style="width:100%;margin-bottom:.75rem">
                <input type="file" ref="fileInput" @input="pickFile" accept=".png, .jpg">
            </div>
        </div>
        <div id="toolbar">
            <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-header" value="2"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-indent" value="+1"></button>
                <button class="ql-indent" value="-1"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-clean"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-link"></button>
                <button class="ql-image"></button>
                <button class="ql-video"></button>
            </span>
            <!-- <span class="ql-formats"> -->
                <!-- <button id="custom-button"><i class="fab fa-twitter"></i></button> -->
                <!-- <button class="ql-yt"><i class="fab fa-youtube"></i></button> -->
            <!-- </span> -->
        </div>
        <quill-editor
            v-model:value="body"
            :options="editorOption"
            :disabled="false"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onEditorChange($event)"/>
    </main>
</template>

<script>
import marked from 'marked';
import { Calendar, DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'

export default {
    components: {
        Calendar,
        DatePicker
    },
	data() {
		return {
            processing: false,
            image: null,
            article: {
                published_at: new Date()
            },
            errors: {},
			content: '',
            users: [],
			editorOption: {
                placeholder: '',
                formats: ['bold','italic','underline','strike','header','list','indent','link','image','video'],
                modules: {
                    toolbar: {
                        container: '#toolbar',
                        handlers: {
                            'link': function(value) {
                                if(value) {
                                    var href = prompt('Enter the URL');
                                    this.quill.format('link', href);
                                } else {
                                    this.quill.format('link', false);
                                }
                            },
                            'yt': function() {
                                let range = this.quill.getSelection();
                                var href = prompt('Enter the URL');
                                this.quill.insertEmbed(range.index, 'video', href);
                                // this.quill.insertText(range.index + 1, '');
                            }
                        }
                    }
                    // toolbar: [
                    //     ['bold', 'italic', 'underline', 'strike'],
                    //     [{ header: 2 }],
                    //     [{ list: 'ordered' }, { list: 'bullet' }],
                    //     [{ indent: '-1' }, { indent: '+1' }],
                    //     // [{ color: [] }, { background: [] }],
                    //     ['clean'],
                    //     ['link', 'image', 'video']
                    // ]
                }
			},
			disabled: false,
		};
	},
    computed: {
        body: {
            get: function() {
                return this.article.body ? marked(this.article.body) : '';
            },
            set: function (v) {
                console.log(v);
                this.article.body = v;
            }
        },
        dateiso: {
            get: function() {
                return this.article.published_at || new Date();
            },
            set: function(date) {
                this.article.published_at = date ? date.toISOString() : null;
            }
        }
    },
	methods: {
        pickFile() {
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.image = e.target.result
                }
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
            }
        },
        getUsers:function(){
            axios.get('/api/admin/users').then(u => {
                if(u.status == 200){
                    this.users = u.data;
                }
            }).catch(e => {
                console.error(e);
            });
        },
		getArticle:function(){
            axios.get('/api/admin/article',{
                params: {
                    id: this.$route.params.id
                }
            }).then(a => {
                if(a.status == 200){
                    console.log(a.data);
                    this.article = a.data;
                }
            }).catch(e => {
                console.error(e);
            });
        },
        setArticle:function(){
            if(!this.processing){
                this.processing = true;
                axios.post('/api/admin/article', {
                    article: this.article,
                    image: this.image
                }).then(response => {
                    console.log(JSON.stringify(response.data));
                    this.processing = false;
                    this.errors = {},
                    this.image = null,
                    this.article = response.data
                    // alert('Updated Successfully');
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            }
        },
		onEditorBlur:function(quill){
			console.log('editor blur!', quill)
		},
		onEditorFocus:function(quill){
			console.log('editor focus!', quill)
		},
		onEditorReady:function(quill){
			console.log('editor ready!', quill)
		},
		onEditorChange:function(quill, html, text){
			console.log('editor change!', quill, html, text)
            console.log(text);
		},
	},
    mounted(){
        this.getUsers();
        if(this.$route.params.id){
            this.getArticle();
        }
    }
};
</script>

<style lang="scss">
    .ql-editor p, .ql-editor ul{
        margin-bottom: 1rem;
    }
</style>
