<template>
    <v-header></v-header>
        <router-view v-slot="{ Component }">
            <component :is="Component"/>
        </router-view>
    <team-modal/>
    <v-footer></v-footer>
</template>

<script>
import teamModal from '../components/global/team-modal.vue';
export default {
  components: { teamModal },
    mounted() {
        document.body.classList.add('splitgate');
    },
    unmounted() {
        document.body.classList.remove('splitgate');
    }
}
</script>

<style lang="scss">
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

html {
    overflow-y: scroll;
}

.slide-fade-enter-active {
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}

.slide-fade-leave-active {
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(1rem);
    opacity: 0;
}

body.splitgate{
    background: $primary-black;
    color: #fff;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;

    ::selection {
        background: $orange;
        color: #fff;
    }
    ::-moz-selection {
        background: $orange;
        color: #fff;
    }

    ::-webkit-scrollbar{
        width: 6px;
    }

    ::-webkit-scrollbar-thumb{
        background: $orange;
        border-radius: 2rem;
    }

    h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
        margin-bottom: unset;
    }

    h1,.h1{
        font-size: 3rem;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
    }

    h2,.h2{
        font-size: 1.5rem;
        line-height: 3rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    h3,.h3{
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    h4,.h4{
        font-size: .825rem;
        line-height: 1.7rem;
        font-weight: 200;
        text-transform: uppercase;
    }

    p{
        font-size: 1rem;
        line-height: 1.75rem;
        color: #AEB3B9;
        font-weight: 200;
    }
    @include media-breakpoint-down(sm){
        h1,.h1{
            font-size: 3rem;
            line-height: 3.5rem;
        }
        h2,.h2{
            font-size: 2rem;
            line-height: 2.5rem;
        }
        h3,.h3{
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    }
}
</style>
