require('./bootstrap');

import VueAxios from 'vue-axios'
import { createApp } from 'vue';
import { createStore } from 'vuex'
import { createWebHistory, createRouter } from "vue-router";
const { DateTime } = require('luxon');
import YoutubeIframe from '@techassi/vue-youtube-iframe';
import VueGtag from 'vue-gtag-next';
import { trackRouter } from "vue-gtag-next";
import { Popover, SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import { quillEditor, Quill } from 'vue3-quill'

axios.interceptors.request.use(
    function(config) {
        config.withCredentials = true;
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

/* Layouts */
import appLayout from './layouts/app.vue';

/* Pages */
import index from './pages/index.vue';
import aboutPage from './pages/about.vue';
import schedulePage from './pages/schedule.vue';
import standingsPage from './pages/standings.vue';
import newsPage from './pages/news.vue';
import articlePage from './pages/article.vue';
import loginPage from './pages/login.vue';
//
import rewardsPage from './pages/rewards.vue';

/* Components */
// Global
import globalheader from './components/global/header.vue';
import globalfooter from './components/global/footer.vue';
import select from './components/global/select.vue';
import button from './components/global/button.vue';
import schedule from './components/global/schedule.vue';
import event from './components/global/event.vue';
import teams from './components/global/teams.vue';
import teamcard from './components/global/teamcard.vue';
import teamModal from './components/global/team-modal.vue';
// Index
import hero from './components/index/hero.vue';
import about from './components/index/about.vue';
import partners from './components/index/partners.vue'
import articlecard from './components/global/article-card.vue'
// Standings
import leaderboarditem from './components/standings/leaderboard-item.vue'
import team from './components/standings/team.vue'
// About
import breakdown from './components/about/breakdown.vue'
import sponsor from './components/about/sponsor.vue'


//! Admin
/* Layouts */
import adminLayout from './admin/layouts/admin.vue';
import adminFrame from './admin/layouts/frame.vue';
/* Pages */
import adminLogin from './admin/pages/login.vue';
import adminDashboard from './admin/pages/dashboard.vue';
import adminSeasons from './admin/pages/scoped/seasons.vue';
import adminSeason from './admin/pages/scoped/season.vue';
import adminEvent from './admin/pages/scoped/event.vue';
import adminPlayers from './admin/pages/scoped/players.vue';
import adminPlayer from './admin/pages/scoped/player.vue';
import adminTeams from './admin/pages/scoped/teams.vue';
import adminTeam from './admin/pages/scoped/team.vue';
import adminStandings from './admin/pages/scoped/standings.vue';
import adminNews from './admin/pages/scoped/news.vue';
import adminArticle from './admin/pages/scoped/article.vue';
import adminContent from './admin/pages/scoped/content.vue';
/* Components */
import adminHeader from './admin/components/frame/header.vue';
import adminSidebar from './admin/components/frame/sidebar.vue';
import adminAvatar from './admin/components/frame/avatar.vue';
//
import adminButton from './admin/components/global/button.vue';
import adminSelect from './admin/components/global/select.vue';
import adminInput from './admin/components/global/input.vue';
import adminTextArea from './admin/components/global/textarea.vue';
import adminWidget from './admin/components/global/widget.vue';
import adminSectionHeader from './admin/components/global/section-header.vue';
//
import adminTableSeasons from './admin/components/scoped/table-seasons.vue';
import adminTableEvents from './admin/components/scoped/table-events.vue';
import adminTablePlayers from './admin/components/scoped/table-players.vue';
import adminTableTeams from './admin/components/scoped/table-teams.vue';
import adminTableNews from './admin/components/scoped/table-news.vue';

/* Store */
const store = createStore({
    state (){
        return{
            mobile: false,
            teamModal: null,
            content: [],
            teams: [],
            partners: [],
            seasons: [],
            articles: [],
            user: {}
        }
    },
    mutations: {
        setMobile(state, mobile){
            state.mobile = mobile;
        },
        setContent(state, content){
            state.content = content;
        },
        setPartners(state, partners){
            state.partners = partners;
        },
        setTeams(state, teams){
            state.teams = teams;
        },
        setSeasons(state, seasons){
            state.seasons = seasons;
        },
        setArticles(state, articles){
            state.articles = articles;
        },
        setUser(state, user){
            state.user = user;
        },
        setTeamModal(state, delta){
            state.teamModal = delta;
        },
    },
    getters: {
        currentSeason: state => {
            return state.seasons.filter(function(s){ return s.active == true; })[0] ? _.orderBy(state.seasons.filter(function(s){ return s.active == true; }), 'start_at', 'desc')[0] : {};
        },
        currentEvent: (state, getters) => {
            if(getters.currentSeason && getters.currentSeason.events){
                let events = _.orderBy(getters.currentSeason.events, 'start_at');
                return events.filter(function(e){ return DateTime.fromISO(e.start_at) > DateTime.now(); })[0];
            }else{
                return {};
            }
        }
    },
    actions: {
        toggleMobile({commit, state}, status){
            if(status != null){
                commit('setMobile', status);
            }else{
                commit('setMobile', !state.mobile);
            }
        },
        getContent({commit}){
            axios.get('/api/content').then(content => {
                if(content.status == 200){
                    commit('setContent', content.data);
                }
            });
        },
        getPartners({commit}){
            axios.get('/api/partners').then(partners => {
                if(partners.status == 200){
                    commit('setPartners', partners.data);
                }
            });
        },
        getTeams({commit}){
            axios.get('/api/teams').then(teams => {
                if(teams.status == 200){
                    commit('setTeams', teams.data);
                }
            });
        },
        getSeasons({commit}){
            axios.get('/api/seasons').then(seasons => {
                if(seasons.status == 200){
                    commit('setSeasons', seasons.data);
                }
            });
        },
        getArticles({commit}){
            axios.get('/api/articles').then(articles => {
                if(articles.status == 200){
                    commit('setArticles', articles.data);
                }
            });
        },
        getUser({commit}){
            return axios.get('/api/user').then(response => {
                commit('setUser', response.data);
                return true;
            }).catch(error => {
                return error;
            });
        },
        login({dispatch}, data){
            return new Promise((resolve, reject) => {
                axios.get('/sanctum/csrf-cookie').then(() => {
                    axios.post('/login', data).then(() => {
                        dispatch('getUser').then(() => {
                            router.push({name: 'admin.dashboard'});
                        });
                    }).catch(error => {
                        console.log(error);
                        if (error.response.status === 422){
                            resolve(error.response.data.errors);
                        }
                    });
                });
            })
        },
        logout(){
            axios.get('/sanctum/csrf-cookie').then(() => {
                axios.post('/logout').then(r => {
                    store.commit('setUser', {});
                    router.push({name: 'login'});
                }).catch(error => {
                    console.error(error);
                });
            });
        },
        openTeamModal({commit}, team){
            commit('setTeamModal', team);
            // document.body.classList.add('locked');
        },
        closeTeamModal({commit}){
            commit('setTeamModal', null);
            // document.body.classList.remove('locked');
        }
    }
})

function isAuthed(to,from,next) {
    if(_.isEmpty(store.state.user)){
        store.dispatch('getUser').then(error => {
            if(_.isEmpty(store.state.user)){
                if(to.name == 'login'){
                    next();
                }else{
                    router.replace({name: 'login'});
                }
            }else{
                if(to.name == 'login'){
                    router.replace({name: 'admin.dashboard'});
                }else{
                    next();
                }
            }
        });
    }else{
        next();
    }
}

/* Routes */
const routes = [
    {
        path: '/',
        component: appLayout,
        children: [
            { path: '', name: 'index', component: index },
            { path: 'about', name: 'about', component: aboutPage },
            { path: 'schedule', name: 'schedule', component: schedulePage },
            { path: 'standings', name: 'standings', component: standingsPage },
            { path: 'news', name: 'news', component: newsPage },
            { path: 'news/:slug', name: 'article', component: articlePage },
            { path: 'rewards', name: 'rewards', component: rewardsPage },
            //
            { path: 'login', name: 'login', component: loginPage, beforeEnter: [isAuthed] },
        ],
    },
    {
        path: '/admin',
        component: adminLayout,
        children: [
            // { path: 'login', name: 'admin.login', component: adminLogin },
            {
                path: '',
                component: adminFrame,
                children: [
                    { path: '', name: 'admin.dashboard', component: adminDashboard },
                    { path: 'seasons', name: 'admin.seasons', component: adminSeasons },
                    { path: 'season/:slug', name: 'admin.season', component: adminSeason },
                    { path: 'season/:slug/event/:eventslug?', name: 'admin.event', component: adminEvent },
                    { path: 'players', name: 'admin.players', component: adminPlayers },
                    { path: 'player/:slug?', name: 'admin.player', component: adminPlayer },
                    { path: 'teams', name: 'admin.teams', component: adminTeams },
                    { path: 'team/:slug?', name: 'admin.team', component: adminTeam },
                    { path: 'standings', name: 'admin.standings', component: adminStandings },
                    { path: 'news', name: 'admin.news', component: adminNews },
                    { path: 'news/:id?', name: 'admin.article', component: adminArticle },
                    { path: 'content', name: 'admin.content', component: adminContent },
                ],
                // beforeEnter: [isAuthed],
            },
        { path: ':catchAll(.*)', redirect: { name: 'admin.dashboard' }}
        ],
        beforeEnter: [isAuthed]
    },
    { path: '/:catchAll(.*)', redirect: { name: 'index' }}
];
const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    history: createWebHistory(),
    routes,
});
trackRouter(router);
const app = createApp({
}).use(router, VueAxios, axios);
app.use(store);
app.use(YoutubeIframe);
app.use(SetupCalendar, {})
app.use(quillEditor);
app.component('Popover', Popover);
app.component('Calendar', Calendar);
// app.component('DatePicker', DatePicker);
app.use(VueGtag, {
    property: {
        id: 'UA-41367224-4'
    }
});
store.watch(
    (state) => state.mobile, (status) => {
    if(status){
            document.body.classList.add('locked');
        }else{
            document.body.classList.remove('locked');
        }
    },
);
store.dispatch('getContent');
store.dispatch('getTeams');
store.dispatch('getPartners');
store.dispatch('getSeasons');
store.dispatch('getArticles');

// Global
app.component('v-header', globalheader);
app.component('v-footer', globalfooter);
app.component('v-button', button);
app.component('v-select', select);
app.component('schedule', schedule);
app.component('event', event);
app.component('teams', teams);
app.component('team-card', teamcard);
app.component('team-modal', teamModal);
// Index
app.component('hero', hero);
app.component('about', about);
app.component('partners', partners);
app.component('article-card', articlecard);
// Standings
app.component('leaderboard-item', leaderboarditem);
app.component('team', team);
// About
app.component('breakdown', breakdown);
app.component('sponsor', sponsor);



//! Admin
app.component('a-header', adminHeader);
app.component('a-sidebar', adminSidebar);
app.component('a-avatar', adminAvatar);
//
app.component('a-button', adminButton);
app.component('a-select', adminSelect);
app.component('a-input', adminInput);
app.component('a-textarea', adminTextArea);
app.component('a-widget', adminWidget);
app.component('a-section-header', adminSectionHeader);
//
app.component('a-table-seasons', adminTableSeasons);
app.component('a-table-events', adminTableEvents);
app.component('a-table-players', adminTablePlayers);
app.component('a-table-teams', adminTableTeams);
app.component('a-table-news', adminTableNews);

app.mount('#app');
