<template>
    <header class="active">
        <div class="container">
            <div :class="[mobile ? 'active' : '', 'wrapper']">
                <router-link :to="{ name: 'index' }">
                    <img src="https://cdn.splitgateproseries.com/pro-series/logos/sps.svg" alt="Splitgate Pro Series">
                </router-link>
                <div class="burger-wrapper" @click="toggleMobile()">
                    <div class="menu"></div>
                    <div class="burger">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
                <nav>
                    <ul>
                        <li>
                            <router-link @click="toggleMobile(false)" :to="{ name: 'index' }">Home</router-link>
                        </li>
                        <li>
                            <router-link @click="toggleMobile(false)" :to="{ name: 'about' }">About</router-link>
                        </li>
                        <li>
                            <router-link @click="toggleMobile(false)" :to="{ name: 'schedule' }">Schedule</router-link>
                        </li>
                        <li>
                            <router-link @click="toggleMobile(false)" :to="{ name: 'standings' }">Standings</router-link>
                        </li>
                        <li>
                            <router-link @click="toggleMobile(false)" :to="{ name: 'news' }">News</router-link>
                        </li>
                        <template v-if="content['global.navigation']">
                            <li v-for="item in JSON.parse(content['global.navigation'])" :key="item.link">
                                <a :href="item.link">{{ item.title }}</a>
                            </li>
                        </template>
                        <li>
                            <a class="compete" @click="toggleMobile(false), fathom('IGFC6KJD')" href="https://playbeyond.com/game/6160aca39fb825001ef1fb0d/" target="_blank">Compete</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    computed: {
        ...mapState([
            'mobile',
            'content'
        ])
    },
    methods: {
        ...mapActions([
            'toggleMobile'
        ]),
        fathom(goal){
            window.fathom.trackGoal(goal, 0);
        },
    },
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
header{
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    transition: all $transition;
    border-bottom: solid 1px unset;
    padding: 1.25rem 0;
    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
            position: relative;
            z-index: 1;
            img{
                height: 3rem;
                transition: filter $transition;
            }
        }
        nav{
            position: relative;
            ul{
                padding: unset;
                margin: unset;
                list-style-type: none;
                display: flex;
                align-items: center;
                li{
                    margin-right: .5rem;
                    &:last-child{
                        margin-right: unset;
                    }
                    a{
                        touch-action: manipulation;
                        user-select: none;
                        flex-direction: column;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        font-size: .825rem;
                        text-decoration: unset;
                        text-transform: uppercase;
                        color: #fff;
                        background-size: 0;
                        border: solid 2px transparent;
                        transition: all .3s ease-in-out;
                        padding: .5rem;
                        border-radius: .5rem;
                        &.compete{
                            background: $red;
                            padding: .5rem 1rem;
                            margin-left: .5rem;
                        }
                        &:hover, &.router-link-exact-active{
                            border: solid 2px $red;
                        }
                    }
                }
            }
            &.mobile{
                display: none;
            }
        }
        .burger-wrapper{
            display: none;
            position: relative;
            height: 2.5rem;
            width: 2.5rem;
            cursor: pointer;
            .menu{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: unset;
                backdrop-filter: blur(.05px);
                border-radius: 50%;
                background: rgba($color: $red, $alpha: .9);
                transition: all 800ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
                transform: scale(0);
                box-shadow: 0 0 0 10px $blue;
            }
            .burger{
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                width: 100%;
                border: solid 2px $red;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                -khtml-border-radius: 50%;
                padding: .5rem;
                transition: all $transition;
                .line{
                    position: relative;
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    margin-bottom: .25rem;
                    transition: all $transition;
                    &:last-child{
                        margin-bottom: unset;
                    }
                }
            }
        }
    }
    &.active{
        background: rgba($color: $primary-black, $alpha: .85);
        border-bottom: solid 1px $light-grey;
    }
}
@include media-breakpoint-down(md){
    header{
        padding: 1rem 0;
        .wrapper{
            .burger-wrapper{
                display: flex;
            }
            &.active{
                a{
                    img{
                        filter: saturate(0) brightness(3);
                    }
                }
                nav{
                    height: 100vh;
                    width: 100%;
                    ul{
                        overflow: visible;
                        opacity: 1;
                        height: unset;
                        width: 100%;
                    }
                }
                .burger-wrapper{
                    .menu{
                        transform: scale(50);
                    }
                    .burger{
                        border: solid 2px #fff;
                        .line{
                            margin-bottom: unset;
                            transform: rotate(-45deg);
                            top: -1px;
                            &:first-child{
                                transform: rotate(45deg);
                                top: 1px;
                            }
                        }
                    }
                }
            }
            nav{
                display: flex;
                position: fixed;
                top: 0;
                left: 0;
                height: 0;
                width: 0;
                align-items: center;
                justify-content: center;
                ul{
                    opacity: 0;
                    overflow: hidden;
                    height: 0;
                    width: 0;
                    flex-direction: column;
                    transition: opacity $transition;
                    transition-delay: 300ms;
                    li{
                        margin-right: unset;
                        margin-bottom: 1rem;
                        &:last-child{
                            margin-top: .25rem;
                            margin-bottom: unset;
                        }
                        a{
                            font-size: 1.25rem;
                            font-weight: 800;
                            border: unset;
                            &:hover, &.router-link-exact-active{
                                border: none;
                            }
                        }
                        a.compete{
                            background: $blue;
                        }
                    }
                }
            }
        }
    }
}
</style>
