<template>
    <section>
        <a-section-header>
            <template v-slot:title>
                Seasons
            </template>
            <!-- <template v-slot:button>
                <a-button>Add Season</a-button>
            </template> -->
        </a-section-header>
        <a-table-seasons :data="seasons" :headers="[{name:'Name',value:'name'},{name:'Slug',value:'slug'}]"/>
    </section>
</template>

<script>
export default {
    data() {
        return {
            seasons: [],
        };
    },
    methods: {
        getSeasons:function(){
            axios.get('/api/admin/seasons').then(s => {
                if(s.status == 200){
                    this.seasons = s.data;
                }
            }).catch(e => {
                console.error(e);
            });
        },
    },
    mounted(){
        this.getSeasons();
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
</style>
