<template>
    <div class="article-card-wrapper col-md-4 col-6 article">
        <router-link :to="{ name: 'article', params:{ slug: article.slug }}" class="col-md-4 col-6 article">
            <div class="image-wrapper">
                <img class="image" :src="article.image">
            </div>
            <div class="title-wrapper">
                <h3>{{ article.title }}</h3>
            </div>
            <h4>{{ published }}</h4>
        </router-link>
    </div>
   <!-- <div class="article-card-wrapper col-md-4 col-6 article">
        <router-link :to="{ name: 'article', params:{ slug: article.slug }}" class="loading">
            <div class="image-wrapper">
                <div class="image"></div>
            </div>
            <div class="title-wrapper">
                <div class="loading"></div>
                <div class="loading"></div>
            </div>
                <div class="loading"></div>
        </router-link>
   </div> -->
</template>

<script>
const { DateTime } = require('luxon');
export default {
    props: ['article'],
    computed: {
        published(){
            let article = DateTime.fromISO(this.article.published_at);
            return article.diff(DateTime.now(), 'days').toObject().days < -7 ?
            article.toFormat('dd LLL yyyy') :
            article.toRelativeCalendar();
        },
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.article-card-wrapper{
    margin-bottom: 3rem;
    .article{
        text-decoration: unset;
        .image-wrapper{
            position: relative;
            padding-top: 56.25%;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.5rem;
            overflow: hidden;
            transition: border $transition;
            background: $primary-black;
            .image{
                padding: .25rem;
                border-radius: .9rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                overflow: hidden;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
        .title-wrapper{
            height: 4rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: .75rem;
            h3{
                text-transform: unset;
                color: #fff;
            }
            .loading{
                background: $light-grey;
                margin-bottom: 1rem;
                width: 100%;
                border-radius: .5rem;
                position: relative;
                overflow: hidden;
                height: 1.5rem;
                &:nth-child(2){
                    width: 85%;
                }
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    background: linear-gradient(90deg, #23272A 0%, #2A3136 52.6%, #23272A 100%);
                    background-repeat: no-repeat;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: block;
                    animation: 2s placeHolderShimmer linear infinite;
                }
            }
        }
        h4{
            color: #AEB3B9;
            font-size: 1rem;
            font-weight: 500;
        }
        &:nth-child(odd){
            .image-wrapper{
                border: solid 2px $orange;
            }
        }
        &:nth-child(even){
            .image-wrapper{
                border: solid 2px $blue;
            }
        }
        &:hover{
            .image-wrapper{
                border: solid 2px #fff;
            }
        }
        &.loading{
            .image-wrapper{
                background: $light-grey;
                border: unset;
                .image{
                    padding: unset;
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';
                        background: linear-gradient(90deg, #23272A 0%, #2A3136 52.6%, #23272A 100%);
                        background-repeat: no-repeat;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: block;
                        animation: 2s placeHolderShimmer linear infinite;
                    }
                }
            }
        }
    }
    @keyframes placeHolderShimmer {
        0% {
            transform: translateX(-500px);
        }

        100% {
            transform: translateX(500px);
        }
    }
}
@include media-breakpoint-down(lg){
    .article-card-wrapper{
        .article{
            .title-wrapper{
                height: 3.25rem;
                h3{
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                }
            }
        }
    }
}
@include media-breakpoint-down(md){
    .article-card-wrapper{
        .article{
            margin-bottom: 2rem;
            .image-wrapper{
                border-radius: .5rem;
                margin-bottom: 1rem;
                .image{
                    border-radius: .4rem;
                }
            }
            .title-wrapper{
                margin-bottom: 0;
                h3{
                    font-size: 1.1rem;
                    line-height: 1.6rem;
                }
            }
            h4{
                font-size: 1rem;
            }
        }
        &:first-of-type{
            width: 100%;
            .article{
                .title-wrapper{
                    margin-bottom: .5rem;
                    height: 2rem;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    h3{
                        font-size: 1.75rem;
                        line-height: 2.25rem;
                    }
                }
            }
        }
    }
}
</style>
