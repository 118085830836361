<template>
    <transition name="slide-fade">
        <div class="team-modal_wrapper" v-if="teamModal">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="team-modal">
                            <div class="team-modal_header">
                                <div class="team-modal_details">
                                    <img :src="team.logo" :alt="team.name" v-if="team.logo">
                                    <h2>{{ team.name }}</h2>
                                </div>
                                <div @click="closeTeamModal" class="team-modal_x"><i class="far fa-times"></i></div>
                            </div>
                            <template v-for="player in team.players" :key="player.id">
                                <div class="team-modal_name">
                                    <h2>{{ player.name }}</h2>
                                    <ul class="team-modal_socials">
                                        <li v-for="social in player.socials" :key="social.id">
                                            <a target="_blank" :href="social.link"><i :class="['fab',social.platform.icon]"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div @click="closeTeamModal" class="team-modal_close"></div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['teams','teamModal']),
        team(){
            let t = this;
            return this.teams.filter(function(team){ return team.id == t.teamModal; })[0];
        }
    },
    methods: {
        ...mapActions(['closeTeamModal'])
    }
}
</script>

<style lang="scss" scoped>
@import './resources/sass/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.team-modal_wrapper{
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: $primary-black, $alpha: .5);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    .team-modal{
        position: relative;
        z-index: 1;
        background: $primary-black;
        border-radius: 1rem;
        border: solid 1px $light-grey;
        padding: 1.5rem;
        .team-modal_header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
            .team-modal_details{
                display: flex;
                align-items: center;
                margin-right: 2rem;
                img{
                    height: 2.5rem;
                    margin-right: 1rem;
                }
                h2{
                    font-weight: 300;
                    white-space: nowrap;
                }
            }
            .team-modal_x{
                width: 2rem;
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background .2s ease-in-out;
                background: unset;
                border-radius: 50%;
                cursor: pointer;
                i{
                    font-size: 1.25rem;
                }
                &:hover{
                    background: #3D4246;
                }
            }
        }
        .team-modal_name{
            background: $light-grey;
            padding: .75rem 1.5rem;
            margin-bottom: .5rem;
            border-radius: 1rem;
            border: solid 1px #3D4246;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:last-child{
                margin-bottom: unset;
            }
            ul.team-modal_socials{
                padding: unset;
                margin: unset;
                list-style-type: none;
                display: flex;
                align-items: center;
                li{
                    margin-right: 1rem;
                    a{
                        font-size: 1.25rem;
                        color: #fff;
                    }
                }
            }
        }
    }
    .team-modal_close{
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
