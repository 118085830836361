<template>
    <div class="wrapper">
        <label>{{ name }}</label>
        <h1>{{ value }}</h1>
    </div>
</template>

<script>
export default {
    props: ['name','value']
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.wrapper{
    background: #fff;
    padding: 2rem;
    border-radius: .25rem;
    label{
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: .25rem;
        color: $light-foreground;
    }
}
</style>
