<template>
    <div class="heading">
        <div class="leading">
            <h1><slot name="title"></slot></h1>
        </div>
        <div class="trailing">
            <slot name="button"></slot>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.heading{
    width: 100%;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .trailing{
        display: flex;
        align-items: center;
    }
}
</style>