<template>
    <main>
        <section id="login">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-8 wrapper">
                        <form action="" method="post" @submit="submit">
                            <input type="text" placeholder="admin@domain.com" v-model="data.email">
                            <small>{{ errors.email ? errors.email[0] : '' }}</small>
                            <input type="password" placeholder="" v-model="data.password">
                            <small>{{ errors.password ? errors.password[0] : '' }}</small>
                            <input type="checkbox" v-model="data.remember">
                            <button type="submit">Log In</button>
                        </form>
                        {{ $store.state.user }}
                        <button @click="register()">Register</button>
                        <button @click="user()">User</button>
                        <button @click="logout()">Logout</button>
                        <button @click="db()">db</button>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data(){
        return {
            data: {},
            errors: {}
        }
    },
    methods: {
        ...mapActions([
            'getUser'
        ]),
        submit(form){
            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/login', this.data).then(response => {
                    this.getUser().then(() => {
                        this.$router.push({name: 'admin.dashboard'});
                    });
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            });
            form.preventDefault();
        },
        user(){
            console.info('User');
            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.get('/api/user').then(response => {
                    console.log(response);
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            });
        },
        db(){
            this.$router.push({name: 'admin.dashboard'});
        },
        logout(){
            console.info('Logout');
            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/logout').then(response => {
                    console.log(response);
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            });
        },
        register(){
            console.info('reg');
            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/register',{
                    name: 'name',
                    email: 'name@email.com',
                    password: 'password'
                }).then(response => {
                    console.log(response);
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

</style>
