<template>
    <main>
        <a-section-header>
            <template v-slot:title>
                {{ season.name }}
            </template>
            <template v-slot:button>
                <a-button to="admin.event" :params="{slug: $route.params.slug}">Add Event</a-button>
            </template>
        </a-section-header>
        <div class="row">
            <div class="col-md-6">
                <a-input label="Name" placeholder="Season One" v-model="season.name" :error="errors.name ? errors.name[0] : null"/>
            </div>
            <div class="col-md-6">
                <a-input label="Slug" placeholder="s1" v-model="season.slug" :error="errors.slug ? errors.slug[0] : null"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-end mt-2">
                <a-button class="small" @click="setSeason()">Save</a-button>
            </div>
        </div>
        <h3>Events</h3>
        <a-table-events :data="season.events" :headers="[{name:'Name',value:'name'},{name:'Slug',value:'slug'}]"/>
    </main>
</template>

<script>
export default {
    data() {
        return {
            season: {},
            errors: {}
        };
    },
    methods: {
        updateSlug:function(){
            this.season.slug = this.season.name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
        },
        getSeason:function(){
            axios.get('/api/admin/season',{
                params: {
                    slug: this.$route.params.slug
                }
            }).then(s => {
                if(s.status == 200){
                    this.season = s.data;
                }
            }).catch(e => {
                console.error(e);
            });
        },
        setSeason:function(){
            axios.post('/api/admin/season', this.season).then(response => {
                // console.log(response.data);
                alert('Updated Successfully');
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    // console.log(this.errors);
                }
            });
        }
        // submit(form){
        //     form.preventDefault();

        //     axios.post('/api/admin/player', this.player).then(response => {
        //         // console.log(response.data);
        //         alert('Updated Successfully');
        //     }).catch(error => {
        //         if (error.response.status === 422) {
        //             this.errors = error.response.data.errors || {};
        //             // console.log(this.errors);
        //         }
        //     });
        // },
    },
    mounted(){
        if(this.$route.params.slug){
            this.getSeason();
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

</style>
