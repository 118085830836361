<template>
    <main>
         <a-section-header>
            <template v-slot:title>
                Event
            </template>
            <template v-slot:button>
                <a-button @click="setEvent()">Save</a-button>
            </template>
        </a-section-header>
        <div class="row mb-2">
            <div class="col-12">
                <img :src="event.newlogo || event.logo" alt="" style="max-width: 10rem;margin-bottom:.75rem">
                <input type="file" ref="fileInput" @input="pickFile" accept=".png,.svg">
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4">
                <a-input label="Name" v-model="event.name" placeholder="Week One" v-on:input="updateSlug" :error="errors.name ? errors.name[0] : null" class="mb-2"/>
            </div>
            <div class="col-md-4">
                <a-input label="Slug" v-model="event.slug" placeholder="week-one" :error="errors.slug ? errors.slug[0] : null" class="mb-2"/>
            </div>
            <div class="col-md-4">
                <a-input label="Prize Pool" placeholder="1000" v-model="event.prizepool" :error="errors.prizepool ? errors.prizepool[0] : null" class="mb-2"/>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6">
                <a-input label="Button Text" placeholder="Join Now" v-model="event.button_text" class="mb-2"/>
            </div>
			<div class="col-md-6">
                <a-input label="Button Link" placeholder="https://playbeyond.com/event/sps-launch-q1/overview" v-model="event.button_link" class="mb-2"/>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                Start Date
                <DatePicker v-model="startdateiso" mode="dateTime" timezone="UTC" is-expanded/>
            </div>
            <div class="col-md-4">
                Finish Date
                <DatePicker v-model="finishdateiso" mode="dateTime" timezone="UTC" is-expanded/>
            </div>
        </div>
    </main>
</template>

<script>
import { Calendar, DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'

export default {
    components: {
        Calendar,
        DatePicker
    },
    data() {
        return {
            event: {
                start_at: new Date()
            },
            errors: {},
            types: [],
            newlogo: null
        };
    },
    computed: {
        startdateiso: {
            get: function(){
                return this.event.start_at || new Date();
            },
            set: function(date){
                this.event.start_at = date ? date.toISOString() : null;
            }
        },
        finishdateiso: {
            get: function(){
                return this.event.finish_at;
            },
            set: function(date){
                this.event.finish_at = date ? date.toISOString() : null;
            }
        }
    },
    methods: {
        updateSlug:function(){
            this.event.slug = this.event.name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
        },
        pickFile() {
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.event.newlogo = e.target.result
                }
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
            }
        },
        getEvent:function(){
            axios.get('/api/admin/event',{
                params: {
                    slug: this.$route.params.eventslug
                }
            }).then(e => {
                if(e.status == 200){
                    this.event = e.data;
                    console.log(e.data);
                }
            }).catch(e => {
                console.error(e);
            });
        },
        setEvent:function(){
            axios.post('/api/admin/event',{
                season: this.$route.params.slug,
                event: this.event,
            }).then(response => {
                console.log(JSON.stringify(response.data));
                this.errors = {};
                this.event = response.data;
                alert('Updated Successfully');

            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                }
            });
        }
    },
    mounted(){
        if(this.$route.params.eventslug){
            this.getEvent();
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
</style>
