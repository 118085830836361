<template>
    <main>
        <a-section-header>
            <template v-slot:title>
                Team
            </template>
        </a-section-header>
        <form action="" method="post" @submit="submit">
            <div class="row mb-3">
                <div class="col-md-6">
                    <a-input label="Name" v-model="team.name" v-on:input="updateSlug" :error="errors.name ? errors.name[0] : null"/>
                </div>
                <div class="col-md-6">
                    <a-input label="Slug" v-model="team.slug" :error="errors.slug ? errors.slug[0] : null"/>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <img :src="team.newlogo || team.logo" alt="" style="max-width: 10rem;margin-bottom:.75rem">
                    <input type="file" ref="fileInput" @input="pickFile" accept=".png">
                </div>
                <div class="col-md-6">
                    <label for="featured" style="margin-right: .75rem;">Featured</label>
                    <input id="featured" type="checkbox" :checked="team.featured" v-model="team.featured">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <a-button class="primary mt-3" type="submit">{{ $route.params.slug ? 'Update' : 'Save' }}</a-button>
                </div>
            </div>
        </form>
    </main>
</template>

<script>
export default {
    data() {
        return {
            team: {},
            errors: {},
            logo: null
        };
    },
    methods: {
        updateSlug:function(){
            this.team.slug = this.team.name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
        },
        pickFile() {
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.team.newlogo = e.target.result
                }
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
            }
        },
        getTeam:function(){
            axios.get('/api/admin/team',{
                params: {
                    slug: this.$route.params.slug
                }
            }).then(p => {
                if(p.status == 200){
                    this.team = p.data;
                }
            }).catch(e => {
                console.error(e);
            });
        },
        submit(form){
            form.preventDefault();
            axios.post('/api/admin/team', this.team).then(res => {
                console.log(res);
                alert('Updated Successfully');
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                }
            });
        },
    },
    mounted(){
        if(this.$route.params.slug){
            this.getTeam();
        }
    }
}
</script>

<style lang="scss" scoped>
@import './resources/js/admin/admin.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

</style>
