<template>
    <main>
         <a-section-header>
            <template v-slot:title>
                News
            </template>
            <template v-slot:button>
                <a-button to="admin.article">Add Article</a-button>
            </template>
        </a-section-header>
        <a-table-news :data="articles" :headers="[{name:'Title',value:'title'}]"/>
    </main>
</template>

<script>
export default {
    data() {
        return {
            loadingArticles: true,
            articles: []
        };
    },
    methods: {
        getArticles:function(){
            axios.get('/api/admin/articles').then(a => {
                if(a.status == 200){
                    this.articles = a.data;
                    this.loadingArticles = false;
                }
            }).catch(e => {
                console.error(e);
            });
        }
    },
    mounted(){
        this.getArticles();
    }
}
</script>

<style>

</style>
